import React, { Component } from 'react'
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, InputGroup, Label, Row, Table } from 'reactstrap';
import Select from 'react-select';
import languageService from '../../../../services/LanguageService';
import { Toaster, ToasterType } from '../../../component/Toaster';
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';
import { MdWarning } from 'react-icons/md';
import ConfirmationPopup from '../../../component/Modals/ConfirmationPopup';
import DocumentFormPopup from "../../../component/Modals/AddDocument";

interface IState {
  languages: any;
  lanugagesSelected: any;
  proficiencyLevel: any;
  languageIstSelected: any;
  language2ndSelected: any;
  language3rdSelected: any;
  languageUpdateData: any;
  documentFormPopup: boolean;
  update: boolean;  
  confirmationPopup: boolean;
  name?: any;
  documentIndex: any;
}
interface IProps {
  handleProficiency?: any;
  handleLangauges?: any;
  languageEdit?: any;
  handleInputFile?: any;
  handleDelete?: any;
  files?: any;
}
const language = [
    { value: "english", label: "English" },
    { value: "arabic", label: "Arabic" },
    { value: "russian", label: "Russian" },
    { value: "chinies", label: "Chinies" },
    { value: "japanies", label: "Japanies" },
];
export default class AddTechnicianLangauge extends Component<IProps, IState> {
  public state = {
    languages: [],
    lanugagesSelected: [],
    proficiencyLevel: [],
    languageIstSelected: 0,
    language2ndSelected: 0,
    language3rdSelected: 0,
    languageUpdateData: [],
    documentFormPopup: false,
    update: false,    
    confirmationPopup: false,
    name: "",
    documentIndex: 0,
  };

  async componentDidMount() {
    let languages: any = await languageService.getLanguage();
    if (languages) {
      this.setState({
        languages: languages.data?.map((e: any) => {
          return {
            label: e.name,
            value: e.id,
          };
        }),
      });
    }
  }

  componentDidUpdate(prev: any, next: any) {
    if (prev.languageEdit.length === 0 && this.props.languageEdit.length > 0) {
      if (this.props.languageEdit.length > 0) {
        let updatedist: any[] = [];
        updatedist = this.props.languageEdit?.map((l: any, index: any) => {
          return {
            ...l,
            prof: l?.language_level,
            index: index + 1,
          };
        });
        this.setState({
          lanugagesSelected: updatedist,
          languageIstSelected: updatedist.find((x) => x.index === 1)?.id,
          language2ndSelected: updatedist.find((x) => x.index === 2)?.id,
          language3rdSelected: updatedist.find((x) => x.index === 3)?.id,
        });
      }
    }
  }

  oldHandleChange = (e: any, id: any) => {
    let languageArr = [] as any;

    if (id === 1) {
      this.setState({
        languageIstSelected: e,
      });
    } else if (id === 2) {
      this.setState({
        language2ndSelected: e,
      });
    } else {
      this.setState({
        language3rdSelected: e,
      });
    }

    if (this.state.lanugagesSelected.length === 0) {
      languageArr.push({ lang: e, id: id });
      this.setState({
        lanugagesSelected: languageArr,
      });
    } else {
      if (
        this.state.lanugagesSelected?.filter((item: any) => item.id === id)
          .length === 0
      ) {
        languageArr = [...this.state.lanugagesSelected];
        languageArr.push({ lang: e, id: id });
        this.setState({
          lanugagesSelected: languageArr,
        });
      }
    }

    this.props.handleLangauges(languageArr);
  };

  handleChange = (e: any, index: any) => {
    let languageArr: any[] = [];

    if (typeof e === "undefined" || e === null) {
      languageArr = this.state.lanugagesSelected;
      let lang: any = languageArr?.find((item: any) => item.index === index);
      let indexLang = languageArr.indexOf(lang);
      this.languageSelection(index, null);
      languageArr.splice(indexLang, 1);
      this.setState({ lanugagesSelected: languageArr });
      return;
    }

    // chek language already select
    let findLanguage: any = this.state.lanugagesSelected.find(
      (x: any) => x.id === e
    );
    if (findLanguage) {
      if (findLanguage?.index !== index) {
        Toaster("Language already selected", ToasterType.error);
      }
      return;
    }

    this.languageSelection(index, e);

    if (this.state.lanugagesSelected.length === 0) {
      languageArr.push({ id: e, index: index, prof: "" });
      this.setState({
        lanugagesSelected: languageArr,
      });
    } else {
      languageArr = this.state.lanugagesSelected;

      let findLang: any = languageArr?.find((item: any) => item.id === e);
      if (!findLang) {
        if (findLang?.index === index) {
        } else {
          languageArr.push({ id: e, index: index, prof: "" });
        }
        this.setState({
          lanugagesSelected: languageArr,
        });
      }
    }

    // this.props.handleLangauges(languageArr);
  };

  languageSelection(index: any, value: any) {
    if (index === 1) {
      this.setState({
        languageIstSelected: value,
      });
    } else if (index === 2) {
      this.setState({
        language2ndSelected: value,
      });
    } else {
      this.setState({
        language3rdSelected: value,
      });
    }
  }

  handleProficieny = async (e: any, index: any) => {
    let selectedLanguages: any[] = this.state.lanugagesSelected;
    let findLanguage: any = selectedLanguages.find(
      (x: any) => x.index === index
    );
    if (!findLanguage) {
      Toaster("Please select first language", ToasterType.error);
      return;
    }

    let langIndex = selectedLanguages.indexOf(findLanguage);
    findLanguage["prof"] = e;
    selectedLanguages[langIndex] = findLanguage;

    await this.setState({ lanugagesSelected: selectedLanguages });

    this.props.handleLangauges(this.state.lanugagesSelected);
  };

  oldhandleProficieny = (e: any, id: any) => {
    let proficienyArr = [] as any;
    let updatedLanguageArr = [] as any;
    let updatedCheck = [] as any;

    if (this.state.languageUpdateData.length > 0) {
      updatedLanguageArr = [...this.state.languageUpdateData];
      updatedLanguageArr[id - 1].language_level = e.target.value;
      this.setState({
        languageUpdateData: updatedLanguageArr,
      });
    }

    if (this.state.proficiencyLevel.length === 0) {
      proficienyArr.push({ prof: e.target.value, id: id });
      this.setState({
        proficiencyLevel: proficienyArr,
      });
    } else {
      if (
        this.state.proficiencyLevel?.filter((item: any) => item.id === id)
          .length === 0
      ) {
        proficienyArr = [...this.state.proficiencyLevel];
        proficienyArr.push({ prof: e.target.value, id: id });
        this.setState({
          proficiencyLevel: proficienyArr,
        });
      }
    }
    this.props.handleProficiency(proficienyArr);
  };

  handleFile = (file: any, name: any) => {
    this.props.handleInputFile(file, name);
  };

  handleDeleteFile = () => {
    this.props.handleDelete(this.state.documentIndex);
  };

  render() {

    const {  files } = this.props;

    return (
      <div className="tabInnerContent">
        <div className="tabInnerContent">
          <Row>
            <Col lg={12} sm={12}>
              <div className="tableWrapper">
                <Table>
                  <thead>
                    <tr>
                      <th className="wd-60">
                        Select your proficiency in the following
                      </th>
                      <th className="wd-10 text-center">Basic</th>
                      <th className="wd-10 text-center">Intermediate</th>
                      <th className="wd-10 text-center">Expert</th>
                      <th className="wd-10 text-center">Native</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td scope="row">
                        <Select
                          options={this.state.languages}
                          placeholder="Select Language"
                          value={this.state.languages?.filter(
                            (e: any) =>
                              e.value === this.state.languageIstSelected
                          )}
                          isClearable
                          onChange={(e: any) => this.handleChange(e?.value, 1)}
                        />
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            id="l-l-1r1"
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.id === this.state.languageIstSelected &&
                                  e.prof === "basic"
                              ).length > 0
                                ? true
                                : false
                            }
                            name="radio-group-1"
                            value={"basic"}
                            onChange={(e) => this.handleProficieny("basic", 1)}
                          />
                          <Label for="l-l-1r1"></Label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            id="l-1r2"
                            value={"intermediate"}
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "intermediate" &&
                                  e.id === this.state.languageIstSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              this.handleProficieny("intermediate", 1)
                            }
                            name="radio-group-1"
                          />
                          <Label for="l-1r2"></Label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            value={"expert"}
                            id="l-1r3"
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "expert" &&
                                  e.id === this.state.languageIstSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            onChange={(e) => this.handleProficieny("expert", 1)}
                            name="radio-group-1"
                          />
                          <Label for="l-1r3"></Label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            id="l-1r4"
                            value={"native"}
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "native" &&
                                  e.id === this.state.languageIstSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            name="radio-group-1"
                            onChange={(e) => this.handleProficieny("native", 1)}
                          />
                          <Label for="l-1r4"></Label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <Select
                          options={this.state.languages}
                          placeholder="Select Language"
                          value={this.state.languages?.filter(
                            (e: any) =>
                              e.value === this.state.language2ndSelected
                          )}
                          isClearable
                          onChange={(e: any) => this.handleChange(e?.value, 2)}
                        />
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            id="l-2r1"
                            name="radio-group-2"
                            value={"basic"}
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "basic" &&
                                  e?.id === this.state.language2ndSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            onChange={(e) => this.handleProficieny("basic", 2)}
                          />
                          <Label for="l-2r1"></Label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            id="l-2r2"
                            name="radio-group-2"
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "intermediate" &&
                                  e?.id === this.state.language2ndSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            value={"intermediate"}
                            onChange={(e) =>
                              this.handleProficieny("intermediate", 2)
                            }
                          />
                          <Label for="l-2r2"></Label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            id="l-2r3"
                            value={"expert"}
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "expert" &&
                                  e?.id === this.state.language2ndSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            onChange={(e) => this.handleProficieny("expert", 2)}
                          />
                          <Label for="l-2r3"></Label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            id="l-2r4"
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "native" &&
                                  e?.id === this.state.language2ndSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            name="radio-group-2"
                            value={"native"}
                            onChange={(e) => this.handleProficieny("native", 2)}
                          />
                          <Label for="l-2r4"></Label>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td scope="row">
                        <Select
                          options={this.state.languages}
                          placeholder="Select Language"
                          value={this.state.languages?.filter(
                            (e: any) =>
                              e.value === this.state.language3rdSelected
                          )}
                          isClearable
                          onChange={(e: any) => this.handleChange(e?.value, 3)}
                        />
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            id="l-3r1"
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "basic" &&
                                  e?.id === this.state.language3rdSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            name="radio-group-3"
                            value={"basic"}
                            onChange={(e) => this.handleProficieny("basic", 3)}
                          />
                          <Label for="l-3r1"></Label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            id="l-3r2"
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "intermediate" &&
                                  e?.id === this.state.language3rdSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            name="radio-group-3"
                            value={"intermediate"}
                            onChange={(e) =>
                              this.handleProficieny("intermediate", 3)
                            }
                          />
                          <Label for="l-3r2"></Label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "expert" &&
                                  e?.id === this.state.language3rdSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            id="l-3r3"
                            name="radio-group-3"
                            value={"expert"}
                            onChange={(e) => this.handleProficieny("expert", 3)}
                          />
                          <Label for="l-3r3"></Label>
                        </div>
                      </td>
                      <td className="text-center">
                        <div className="custRadio">
                          <Input
                            type="radio"
                            id="l-3r4"
                            checked={
                              this.state.lanugagesSelected?.filter(
                                (e: any, index: any) =>
                                  e.prof === "native" &&
                                  e?.id === this.state.language3rdSelected
                              ).length > 0
                                ? true
                                : false
                            }
                            value={"native"}
                            onChange={(e) => this.handleProficieny("native", 3)}
                            name="radio-group-3"
                          />
                          <Label for="l-3r4"></Label>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </div>
            </Col>

            <Col lg={12} sm={12}>
              <FormGroup>
                <div className="docWrapper">
                  <Card className="cardBox">
                    <CardHeader className="d-flex justify-content-between align-items-center h-38 mb-0 br-0">
                      <div className="d-flex align-items-center">
                        <h2>Language Document</h2>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          onClick={() => {
                            this.setState({
                              documentFormPopup: true,
                              update: false,
                            });
                          }}
                          className="btn-size tableBtn"
                          outline
                        >
                          Add Document
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="colList">
                        <ul className="p-0 min-h-150">
                          {files && files.length > 0 ? (
                            files.map((e: any, index: any) => {
                              return (
                                <li key={index}>
                                  <div className="docContent d-flex justify-content-between align-items-center w-100">
                                    <div className="docName">
                                      <span>{e.name}</span>
                                    </div>
                                    <div className="docAction d-flex justify-content-end align-items-center">
                                      <Button
                                        outline
                                        onClick={() => {
                                          this.setState({
                                            confirmationPopup: true,
                                            name: e.name,
                                            documentIndex: index,
                                          });
                                        }}
                                        className="ml-2"
                                      >
                                        <FaTrash />
                                      </Button>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <div className="noData">
                              <MdWarning size={32} />
                              <p>No File Available</p>
                            </div>
                          )}
                        </ul>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <DocumentFormPopup
            modal={this.state.documentFormPopup}
            handleInputFile={this.handleFile}
            toggle={() => this.setState({ documentFormPopup: false })}
            type={this.state.update ? "Update Document" : "Add Document"}
            isOfficialDoc={false}
          />
          <ConfirmationPopup
            modal={this.state.confirmationPopup}
            toggle={() => this.setState({ confirmationPopup: false })}
            type="Documents"
            handleDelete={this.handleDeleteFile}
            icon={<FaTrash />}
            message={"Are you sure you want to remove this Document?"}
          />
        </div>
      </div>
    );
  }
} 
