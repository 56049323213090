import { usePlacesWidget } from "react-google-autocomplete";

export default (props) => {
    const { ref } = usePlacesWidget({
        apiKey: "AIzaSyAP_-iV2ekkQVU6Cp7pBrplEfLX5oWmbvs",
        onPlaceSelected: (place) => {props.selectLocation(place)},
        options: {
            types: ["geocode","establishment"]
        },
    });
    
    return (
        
            <input
                ref={ref}
            defaultValue={props.defaultAddress}            
            disabled={props.isDisabled}
            className={props.className}
                style={{ width: "100%" }}
            
            />
    )
};