import React from "react";
import AuthForm from "./AuthPasswordForm";
import AuthService from "../../services/AuthService";
import { Toaster, ToasterType } from "../component/Toaster";
import { match } from "react-router-dom";


export const STATE_FORGOT_PASSWORD = "FORGOT_PASSWORD";
export const STATE_RESET_PASSWORD = "RESET_PASSWORD";

interface DetailParams {
  token: any;
}

interface IProps {
  history: { push(url: string): void };
  authState: string;
  match?: match<DetailParams>;
}


class AuthPasswordPage extends React.Component<IProps> {
  
  public handleAuthState = (authState: any) => {
    if (authState === STATE_FORGOT_PASSWORD) {
      this.props.history.push("/forgotPassword");
    } else {
      this.props.history.push("/resetPassword");
    }
  };
  

  redirectToDashboard() {
    window.location.replace("/");
  }

  handleSubmit = async (e:any) => {    
    try {

      let isForgot = e?.isForgot
      if (isForgot === true) {
        let appUrl = `${process.env.REACT_APP_SOURCE_URL}/#/resetPassword`;
        await AuthService.forgotPassword(e?.email, appUrl).then((res: any) => {          
          if (res?.status) {
            Toaster(res?.status, ToasterType.success)
            this.props.history.push('/login')
         }
        });
        
      } else {
        await AuthService.resetPassword(
          e?.email,
          e?.password,
          e?.confirmpassword,
          this.props.match?.params?.token
        ).then((res: any) => {
          if (res?.message) {
            Toaster(res?.message, ToasterType.success);
            this.props.history.push("/login");
          }
        });
        
      }
      
    } catch (error) {
      Toaster(error?.message, ToasterType.error);
    }
  };

  public render() {
    let element = document.querySelector(".cr-sidebar");
    if (element) {
      element.classList.remove("cr-sidebar--open");
    }

    return (
      <div className="loginWrapper">
        <div className="d-flex justify-content-between align-items-center h-100">
          <div className="loginLeftWrapper d-flex justify-content-between align-items-center flex-fill wd-40 h-100">
            <div className="loginContent ">
              <h2>Welcome to Quattris</h2>
              <p>
                We understand that our clients are looking for much more than a
                supplier when appointing a third party IT supplier to work on
                their critical system. From appointing the right project
                manager, to the handover or live date and beyond, Quattris take
                great care in planning every detail to ensure success.
              </p>
            </div>
          </div>
          <div className="loginRightWrapper d-flex justify-content-between align-items-center flex-fill wd-60  h-100">
            <div className="loginFormContent ">
              <AuthForm
                authState={this.props.authState}
                onChangeAuthState={this.handleAuthState}
                onSubmit={this.handleSubmit}
                token={this.props?.match?.params?.token}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthPasswordPage;
