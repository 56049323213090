import React from "react";

import bn from "../../utility/bemnames";

import { Container } from "reactstrap";

const bem = bn.create("content");
type AClickEvent = React.MouseEvent<HTMLAnchorElement>;


interface IProps {
  Tag?: any;
  className: any;
  onClick?: (x: AClickEvent) => void;
}

class Content extends React.Component<IProps> {
 
  public static defaultProps = {
    Tag: Container,
  };

  render() {
    const { Tag, className, ...props } = this.props;

    const classes = bem.b(className);    
    return <Tag className={classes} {...props} />;
  }
}

export default Content;
