import classnames from 'classnames';
import React, { Component } from 'react'
import Select from 'react-select';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";

interface IState {
  activeState: string;
  isVisible: boolean;
  dob:Date,
}
interface IProps {
  handleChange?: any;
  handleDropdown?: any;
  handleDate?: any;
  invalidFirstName?: any;
  invalidLastName?: any;
  inValidDOB?: any;
  inValidgender?: any;
  technicianObj?: any;
  first_name?: any;
  last_name?: any;
  disablity?:any;
}
export default class AddTechnicianPersonalInfo extends Component<IProps, IState> {

  public state = {
    activeState: "",
    isVisible:false,
    dob:new Date()
  };

  componentDidMount() {    
    if (
      this.props.disablity !== null &&
      this.props.disablity !== "" &&
      this.props.disablity !== "No" &&
      this.props.disablity !== false
    ) {
      this.setState({
        isVisible: true,
        activeState: "on",
      });
    } else if (this.props.disablity && this.props.disablity === "No") {
      this.setState({
        isVisible: false,
        activeState: "off",
      });
    }
  }

  componentDidUpdate(prevProps: IProps) {
    if (prevProps.disablity !== this.props.disablity) {
      if (this.props.disablity !== null && this.props.disablity !== "" && this.props.disablity !== "No" && this.props.disablity !== false) {
        this.setState({
          isVisible: true,
          activeState: "on",
        });
      } else if(this.props.disablity && this.props.disablity === "No") {
        this.setState({
          isVisible: false,
          activeState: "off",
        });
      }
    }
  }

  handleDateChange(e:any){
    this.props.handleDate(e,'dob')
    this.setState({dob:e})
  }
  render() {

    const {
      first_name,
      last_name,
      handleChange,
      handleDropdown,
      handleDate,
      invalidLastName,
      invalidFirstName,
      inValidDOB,
      inValidgender,
      technicianObj,
      disablity,
    } = this.props;
    const toggle = (tab: any) => {
      if (this.state.activeState !== tab) this.setState({ activeState: tab });
    };
    const gender = [
      { value: "male", label: "Male" },
      { value: "female", label: "female" },
    ];
    
    return (
      <div className="tabInnerContent">
        <Row>
          <Col lg={8} sm={12}>
            <Row>
              <Col lg={12} sm={12}>
                <FormGroup>
                  <Label for="firstname">
                    First Name<sup>*</sup>
                  </Label>
                  <Input
                    type="text"
                    name="first_name"
                    id="firstname"
                    value={first_name}
                    className={ invalidFirstName? "bounce" : ""}
                    onChange={handleChange}
                    placeholder="Enter First Name"
                  />
                </FormGroup>
              </Col>
              <Col lg={12} sm={12}>
                <FormGroup>
                  <Label for="lastName">
                    Last Name<sup>*</sup>
                  </Label>
                  <Input
                    type="text"
                    className={ invalidLastName? "bounce" : ""}
                    value={last_name}
                    name="last_name"
                    id="lastName"
                    placeholder="Enter Last Name"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              {/* <Col lg={12} sm={12}>
                <FormGroup>
                  <Label for="gender">Gender</Label>
                  <Select
                    options={gender}
                    className={ inValidgender? "bounce" : ""}
                    name="gender"
                    id="gender"
                    onChange={(e)=>handleDropdown(e,'gender')}
                    placeholder="Select Gender"
                    isClearable
                  />
                </FormGroup>
              </Col> */}
            </Row>
          </Col>
          <Col lg={4} sm={12}>
            <FormGroup></FormGroup>
          </Col>
        </Row>
        <Row>
          {/* <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="dob">Date of birth</Label>
              <DatePicker
                name="dOB"
                id="dob"
                selected={this.state.dob}
                autoComplete="none"
                placeholderText="Enter Date of Birth"
                onChange={(e)=>this.handleDateChange(e)}
                />
            </FormGroup>
          </Col> */}
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="disablity">Disablity</Label>
              <div className="toggleInput">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <Button
                      outline
                      className={classnames({
                        active: this.state.activeState === "off",
                      })}
                      onClick={(e) => {
                        handleDate(true, "disability");
                        handleDate("No", "disability_text");
                        this.setState({ isVisible: false });
                        toggle("off");
                      }}
                    >
                      No
                    </Button>
                    <Button
                      
                      outline
                      className={classnames({
                        active: this.state.activeState === "on",
                      })}
                      onClick={(e) => {
                        this.setState({ isVisible: true });
                        handleDate(true,'disability')
                        toggle("on");
                      }}
                    >
                      Yes
                    </Button>
                  </InputGroupAddon>
                  {this.state.isVisible && (
                    <Input
                      type="text"
                      name="disability_text"
                      id="disablity"
                      defaultValue={typeof disablity !== 'boolean' ? disablity : ""}
                      placeholder="Enter Disablity"
                      onChange={handleChange}
                      />
                  )}
                </InputGroup>
              </div>
            </FormGroup>
          </Col>
        </Row>
      </div>
    );
  }
}
