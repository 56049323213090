import { Data } from "../../services/TicketService";

export const SET_LOADING = 'SET_LOADING';
export const SET_ERROR = 'SET_ERROR';
export const SET_TICKET = 'SET_TICKET';
export const GET_TICKET_FORM = 'GET_TICKET_FORM';
export const UPDATE_TICKET = 'UPDATE_TICKET';


export interface TicketAction {
    type: typeof GET_TICKET_FORM;
    payload: Data;
}

const initialState = {
    pending: false,
    ticketTechnicians: [],
}

function TicketReducer(state = initialState, action: any) {
    switch (action.type) {        
        case UPDATE_TICKET:
            return {
                ...state,
                ticketTechnicians: action.ticketTechnicians
            }
        default:
            return state;
    }
}

export const getTickets = (state: any) => state.ticketTechnicians;

export default TicketReducer