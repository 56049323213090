import httpServices from "./Helper/HttpService"

const getEmploymentStatus = async ():Promise<any> => {
    try {
        return await httpServices.get("/sf_employment_Status")
    } catch (error) {
        throw error
    }
}

const getEmploymentStatusList = async (): Promise<any> => {
    try {
        let response: any = await httpServices.get("/employment_status");
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const createEmploymentStatus = async (data: any): Promise<any> => {
    try {
        let response: any = await httpServices.post(`/employment_status`, data);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const updateEmploymentStatus = async (id: any, data: any): Promise<any> => {
    try {
        let response: any = await httpServices.put(
            `/employment_status/${id}`,
            data
        );
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const deleteEmploymentStatus = async (id: any): Promise<any> => {
    try {
        let response: any = await httpServices.get(`/delete-employment-status/${id}`);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const restoreEmploymentStatus = async (id: any): Promise<any> => {
    try {
        let response: any = await httpServices.get(`/restore-employment-status/${id}`);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};


export default {
    getEmploymentStatus,
    // services
    getEmploymentStatusList,
    createEmploymentStatus,
    updateEmploymentStatus,
    deleteEmploymentStatus,
    restoreEmploymentStatus,
}