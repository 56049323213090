import { IHttpError } from "../../models/IHttpError";
import Notify, { NotifyType } from "../../utility/Notify";
import LocalStorageService from "../LocalStorageService";
import SessionStorageService, { Key } from "../SessionStorageService";


export const baseURL = process.env.REACT_APP_API_BASE_URL;
export const fileBaseURL = process.env.REACT_APP_FILE_BASE_URL;
export const availableUnauthorizationPageList = ['/login', '/unauthorized'];


export enum Method {
    GET = 'GET',
    POST = 'POST',
    PATCH = 'PATCH',
    PUT = 'PUT',
    DELETE = 'DELETE'
}

export enum ContentType {
    JSON = 'application/json',
    FORM = 'multipart/form-data',
    STREAM = 'application/octet-stream',
    FORM_URLENCODED = 'application/x-www-form-urlencoded,'
}


const makeFormRequest = <T>(method:Method,path: string, data: FormData): Promise<T> => {
    return makeRequest<T>(method, ContentType.FORM, path, data);
};

const makeRequest = async <T>(    
    type: Method,
    contentType: ContentType,
    path: string,
    data?: any,
    fetchOptions?: {}
): Promise<T> => {    
    const token = LocalStorageService.getItem<string>(Key.access_token);
    const headers = {
        'Access-Control-Allow-Headers':"*",
        Accept: ContentType.JSON,
        Authorization: `Bearer ${token}`    
    };
    if (contentType === ContentType.JSON) {
        headers['Content-Type'] = ContentType.JSON;
    }    
    const body = contentType === ContentType.JSON ? data && JSON.stringify(data) : data;
    const response = await window.fetch(`${baseURL}${path}`, {
        ...fetchOptions,
        method: type,
        mode: 'cors',
        cache: 'no-cache',
        headers,
        body
    });
    if (!response.ok) {
        await errorHandler(response);
    }
    return response.json();
};

const makeJsonRequest = <T>(type: Method, path: string, data?: any, fetchOptions?: {}): Promise<T> => {
    return makeRequest<T>(type, ContentType.JSON, path, data, fetchOptions);
};

const getParam= <T>(path: string, params?: any, fetchOptions?: {}) => {    
    let getPath = path
    if (typeof params !== "undefined" && params !== null) {
         

        getPath = `${path}${JSON.stringify(params)}`
        getPath=getPath.replace('{','?')
        getPath=getPath.replaceAll(',','&')
        getPath=getPath.replaceAll('}','')
        getPath=getPath.replaceAll(':','=')
    }
    return makeJsonRequest<T>(Method.GET, getPath, null, fetchOptions);
};

const get = <T>(path: string, params?: any, fetchOptions?: {}) => {    
    let getPath = path
    if (typeof params !== "undefined" && params !== null) {
        getPath = `${path}${JSON.stringify(params)}`

    }
    return makeJsonRequest<T>(Method.GET, getPath, null, fetchOptions);
};

const getJson = <T>(path: string, params?: any, data?:any, fetchOptions?: {}) => {
    let getPath = path
    if (typeof params !== "undefined" && params !== null) {
        getPath = `${path}${JSON.stringify(params)}`

    }
    return makeJsonRequest<T>(Method.GET, getPath, data, fetchOptions);
};

const post = <T>(path: string, data?: any, fetchOptions?: {}) => {
    return makeJsonRequest<T>(Method.POST, path, data, fetchOptions);
};

const patch = <T>(path: string, data?: any) => {
    return makeJsonRequest<T>(Method.PATCH, path, data);
};

const put = <T>(path: string, data?: any) => {
    return makeJsonRequest<T>(Method.PUT, path, data);
};

const remove = <T>(path: string, data?: any, fetchOptions?: {}) => {
    return makeJsonRequest<T>(Method.DELETE, path, data, fetchOptions);
};

const postFormData = <T>(path: string, data: FormData) => {
    return makeFormRequest<T>(Method.POST,path, data);
};

const putFormData = <T>(path: string, data: FormData) => {
    return makeFormRequest<T>(Method.PUT,path, data);
};

const errorStatusHandler = async (response: Response) => {
    switch (response.status) {
        case 401: {
            if (availableUnauthorizationPageList.includes(window.location.pathname)) {
                return;
            }
            break;
            // TODO add something as like "waitingRequestList" for collecting requests, that are were shipped in "unauth" time
            // AuthService.reAuthChecking().catch(AuthService.redirectToLoginPage);
        }
        default:
            return response.json();
    }
};

const errorHandler = (response: Response) => {
    return errorStatusHandler(response).then(defaultErrorHandler);
};

const defaultErrorHandler = async (error: Promise<any>) => {
    if (!error) {
        return;
    }
    const e: IHttpError = (await error) as IHttpError;
    const message = e.error_message || e.message;
    if (message) {
        Notify(NotifyType.Danger, message);
    }
    throw e;
};

const httpServices = {
    get,
    getJson,
    post,
    patch,
    remove,
    put,
    postFormData,
    errorHandler,
    getParam,
    putFormData
}

export default httpServices