import React, { Component } from 'react'
import { Button, Card, CardBody, CardHeader, Col, Input, InputGroup, Label, Row, Table } from 'reactstrap';
import SkillService from '../../../../services/SkillService';
import CheckboxGroup from "react-checkbox-group";

interface IState {
  skills: any,
  years: any,
  skillIDSet: any,
  skillSetData: any,
  skillSetArray: any,
  // skillUpdatedData:any,
  skillSetCheckList: any,
  initialState:boolean
}
interface IProps{
  handleSkills?:any,
  handleSkillSet?:any
  skillSetEdit?:any,
  skillLevelEdit?: any,  
}
export default class AddTechnicianSkillSet extends Component<IProps, IState> {
  public state = {
    skills: [],
    years: [],
    skillIDSet: [],
    skillSetData: [],
    skillSetArray: [],
    skillSetCheckList: [],
    initialState:false,
  };

  async componentDidUpdate(prev: any, next: any) {
    if (prev.skillSetEdit !== this.props.skillSetEdit) {
      if (this.props.skillSetEdit) {
        await this.setState({
          skillSetCheckList: this.props.skillSetEdit?.map((e: any) => {
            return e.id;
          }),
        });

        this.handleCheckList(this.state.skillSetCheckList);
      }
    }
    
    if (
      this.props.skillLevelEdit && this.props.skillLevelEdit.length > 0 &&
      this.state.skills.length > 0 &&
      this.state.initialState === false
    ) {
      if (this.state.skills.length > 0) {
        let skillIDs: any[] = [];
        let years: any[] = [];
        let data: any[] = this.state.skills;
        data?.filter((s: any) => {
          let skillFind: any = this.props.skillLevelEdit?.find(
            (f: any) => f.id === s.id
          );
          if (skillFind) {
            let index = data.indexOf(data.find((d) => d.id === skillFind.id));
            data[index]["checked"] = this.skillLevelSelected(
              skillFind["skill_level"]
            );
            skillIDs.push({
              index: index,
              id: skillFind.id,
            });
            years.push({
              year: skillFind.skill_level,
              index: skillFind.id,
            });
          }
        });

        this.setState({
          initialState: true,
          skills: data,
          skillIDSet: skillIDs,
          years: years,
        });
      } else {
      }
    }
    //   this.setState({
    //     skills: this.props.skillLevelEdit.map((e: any) => {
    //       return {
    //         ...e,
    //         checked: this.skillLevelSelected(e?.skill_level),
    //       };
    //     }),
    //     skillIDSet: this.props.skillLevelEdit.map((e: any,index:any) => {
    //       return {
    //           index:index, id:e.id
    //       }
    //     }),
    //     years: this.props.skillLevelEdit.map((e: any, index: any) => {
    //       return {
    //         year: e.skill_level,
    //         index: e.id,
    //       };
    //     })
    //   });
    // }
  }

  skillLevelSelected = (year: any) => {
    switch (year) {
      case "0 years":
        return { y0: true, y1: false, y2: false, y3: false, y4: false };
      case "1-3 years":
        return { y0: false, y1: true, y2: false, y3: false, y4: false };
      case "3-5 years":
        return { y0: false, y1: false, y2: true, y3: false, y4: false };
      case "5-8 years":
        return { y0: false, y1: false, y2: false, y3: true, y4: false };
      case "8+ years":
        return { y0: false, y1: false, y2: false, y3: false, y4: true };

      default:
        return { y0: false, y1: false, y2: false, y3: false, y4: false };
    }
  };

  async componentDidMount() {
    let skillData: any = await SkillService.getSkills();
    let skillSetData: any = await SkillService.getSkillSet();
    if (skillSetData) {
      this.setState({
        skillSetData: skillSetData.data,
      });
    }

    if (skillData && this.state.skills.length === 0) {
      this.setState({
        skills: skillData?.data?.map((e: any) => {
          return {
            ...e,
            checked: { y0: false, y1: false, y2: false, y3: false, y4: false },
          };
        }),
      });
    }
  }

  handleRadioChange =async (
    e: any,
    value: any,
    col: any,
    index: number,
    id: any
  ) => {
    let findSkill: any = this.state.skills.find((s: any) => s.id === id);
    if (findSkill && findSkill?.checked) {
      let updateList: any[] = this.state.skills;
      let sIndex = updateList.indexOf(findSkill);
      findSkill["checked"] = this.skillLevelSelected(value);
      updateList[sIndex] = findSkill;
      await this.setState({ skills: updateList });
    }

    let updateSkills = [] as any;
    let updateYears = [] as any;
    let updateRadioBtn = [] as any;

    if (this.state.skillIDSet.length === 0) {
      updateSkills.push({ index: index, id: id });
      this.setState({ skillIDSet: updateSkills });
    } else {
      updateSkills = JSON.parse(JSON.stringify(this.state.skillIDSet));
      let findSkill = updateSkills?.find((item: any) => item.index === index);
      if (findSkill) {
        let findex = updateSkills.indexOf(findSkill);
        updateSkills[findex].id = id;
      } else {
        updateSkills.push({ index: index, id: id });
      }
      this.setState({ skillIDSet: updateSkills });
    }

    if (this.state.years.length === 0) {
      updateYears.push({ year: value, index: id });
    } else {
      updateYears = JSON.parse(JSON.stringify(this.state.years));
      let findYear = updateYears?.find((item: any) => item.index === id);
      if (findYear) {
        let yindex = updateYears.indexOf(findYear);
        updateYears[yindex].year = value;
      } else {
        updateYears.push({ year: value, index: id });
      }
    }

    this.setState({ years: updateYears });
    this.props.handleSkills(updateSkills, updateYears);
  };

  // handleSkillSet = (event: any, skillId: any, skillsetId: any, skillSetItemIndex: any) => {
  //   let skillSetArr = [] as any;
  //   if (event.target.checked) {
  //     skillSetArr = [...this.state.skillSetArray]
  //     if (this.state.skillSetArray.filter((id: any) => id === skillId).length !== 0) {
  //       skillSetArr[skillSetItemIndex] = skillId
  //     }
  //     else {
  //       skillSetArr.push(skillId)
  //     }
  //   }
  //   else {
  //     if (this.state.skillSetArray.filter((id: any) => id === skillId).length !== 0) {
  //       skillSetArr = [...this.state.skillSetArray]
  //       skillSetArr = skillSetArr.filter((id: any) => id !== skillId)
  //     }
  //   }
  //   this.setState({
  //     skillSetArray: skillSetArr
  //   })
  // }

  handleCheckList(e: any) {
    this.setState({
      skillSetCheckList: e?.map((c: any) => {
        return c;
      }),
    });
    this.props.handleSkillSet(e);
  }

  render() {
    return (
      <div className="tabInnerContent">
        <Row className="mb-2">
          <Col lg={12}>
            <div className="colList">
              <h2 className="bg-color">
                Select your experties in the following
              </h2>
            </div>
          </Col>
        </Row>
        <Row className="mb-3">
          <Col lg={12} sm={12}>
            <div className="tableWrapper">
              <Table>
                <thead>
                  <tr>
                    <th className="wd-45">Experties</th>
                    <th className="wd-15 text-center">No Experience</th>
                    <th className="wd-10 text-center">1-3 years</th>
                    <th className="wd-10 text-center">3-5 years</th>
                    <th className="wd-10 text-center">5-8 years</th>
                    <th className="wd-10 text-center">8+ years</th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.skills?.map((item: any, index) => {
                    return (
                      <tr key={index}>
                        <td scope="row">{item.name}</td>
                        <td className="text-center">
                          <div className="custRadio">
                            <Input                              
                              checked={item.checked.y0 === true ? true : false}
                              type="radio"
                              id={`s-${index}-y0`}
                              onChange={(e: any) => {
                                this.handleRadioChange(
                                  e,
                                  "0 years",
                                  1,
                                  index,
                                  item.id
                                );
                              }}
                              name={`radio-group-${index}[]`}
                            />
                            <Label for={`s-${index}-y0`}></Label>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="custRadio">
                            <Input                              
                              checked={item.checked.y1 === true ? true : false}
                              type="radio"
                              id={`s-${index}-y1`}
                              onChange={(e: any) => {
                                this.handleRadioChange(
                                  e,
                                  "1-3 years",
                                  1,
                                  index,
                                  item.id
                                );
                              }}
                              name={`radio-group-${index}[]`}
                            />
                            <Label for={`s-${index}-y1`}></Label>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="custRadio">
                            <Input                              
                              checked={item.checked.y2 === true ? true : false}
                              type="radio"
                              id={`s-${index}-y2`}
                              onChange={(e: any) => {
                                this.handleRadioChange(
                                  e,
                                  "3-5 years",
                                  2,
                                  index,
                                  item.id
                                );
                              }}
                              name={`radio-group-${index}[]`}
                            />
                            <Label for={`s-${index}-y2`}></Label>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="custRadio">
                            <Input                              
                              checked={item.checked.y3 === true ? true : false}
                              type="radio"
                              id={`s-${index}-y3`}
                              onChange={(e: any) => {
                                this.handleRadioChange(
                                  e,
                                  "5-8 years",
                                  3,
                                  index,
                                  item.id
                                );
                              }}
                              name={`radio-group-${index}[]`}
                            />
                            <Label for={`s-${index}-y3`}></Label>
                          </div>
                        </td>
                        <td className="text-center">
                          <div className="custRadio">
                            <Input                              
                              checked={item.checked.y4 === true ? true : false}
                              type="radio"
                              id={`s-${index}-y4`}
                              onChange={(e: any) => {
                                this.handleRadioChange(
                                  e,
                                  "8+ years",
                                  4,
                                  index,
                                  item.id
                                );
                              }}
                              name={`radio-group-${index}[]`}
                            />
                            <Label for={`s-${index}-y4`}></Label>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          </Col>
        </Row>
        <Row className="mb-2">
          <Col lg={12}>
            <div className="colList">
              <h2 className="bg-color">
                Indicate which of the following you have worked with. Check all
                boxes that apply to you
              </h2>
            </div>
          </Col>
        </Row>
        <Row>
          {this.state.skillSetData?.map((item: any, index) => {
            return (
              <Col key={index} lg={6} sm={12} className="pb-2">
                <Card className="cardBox h-100">
                  <CardHeader className="d-flex justify-content-between align-items-center h-38 mb-0 br-0">
                    <div className="d-flex align-items-center">
                      <h2>{item.name}</h2>
                    </div>
                    <div className="d-flex align-items-center">
                      {/* <Button className="btn-size tableBtn" outline>
                        Add Another
                      </Button> */}
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="colList">
                      <ul className="p-0">
                        <CheckboxGroup
                          name="skillCheckList"
                          value={this.state.skillSetCheckList}
                          onChange={(e) => {
                            this.handleCheckList(e);
                          }}
                        >
                          {(Checkbox) => (
                            <>
                              {item.skillsets?.map((t: any, index:any) => {
                                return (
                                  <li key={index}>
                                    <Label className="checkBox form-check-label">
                                      {t.name}
                                      <Checkbox value={t.id} />
                                      <span className="checkmark"></span>
                                    </Label>
                                  </li>
                                );
                              })}
                            </>
                          )}
                        </CheckboxGroup>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}
        </Row>
      </div>
    );
  }
}
