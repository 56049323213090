import classnames from 'classnames';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';

import TechnicianService from '../../../../services/TechnicianService';
import CheckboxGroup from "react-checkbox-group";

interface IState {
    toolkitArray: any;
    toolKitCheckList: any;
    
}
interface IProps{
    handleChange:any,
    feedback:any
}
export default class AddTechnicianFeedback extends Component<IProps, IState> {

    public state = {
        toolkitArray: [],
        toolKitCheckList: []
    };
   
    render() {
        const {handleChange}=this.props;
        return (
            <div className="tabInnerContent">
            <Row>
              <Col lg={12} sm={12}>
                <FormGroup>
                  <Label for="feedback">
                    Remarks<sup>*</sup>
                  </Label>
                  <Input
                    type="textarea"
                    name="feedback"
                    id="feedback"
                    value={this.props.feedback}
                    placeholder="Enter Feedback"
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
          </div>
        );
    }
}
