import { ICountry } from "../models/ICountry"
import httpServices from "./Helper/HttpService"


const getLanguage = async ():Promise<any[]> => {
    try {
        let response: any = await httpServices.get("/sf_languages")
        if (response && typeof response?.data === 'string') {
            return []
        } else {
            return response
        }
    } catch (error) {
        throw error
    }
}

// languages
const getLanguageList = async (): Promise<any> => {
    try {
        let response: any = await httpServices.get("/languages");
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const createLanguage = async (data: any): Promise<any> => {
    try {
        let response: any = await httpServices.post(`/languages`, data);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const updateLanguage = async (id: any, data: any): Promise<any> => {
    try {
        let response: any = await httpServices.put(
            `/languages/${id}`,
            data
        );
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const deleteLanguage = async (id: any): Promise<any> => {
    try {
        let response: any = await httpServices.get(`/delete-language/${id}`);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const restoreLanguage = async (id: any): Promise<any> => {
    try {
        let response: any = await httpServices.get(`/restore-language/${id}`);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

export default {
    getLanguage,
    // languages
    getLanguageList,
    createLanguage,
    updateLanguage,
    deleteLanguage,
    restoreLanguage,
}