import httpServices from "./Helper/HttpService"

const getRecruiter = async (): Promise<any> => {
    try {
        return await httpServices.get(`/sf_recruiter`)
    } catch (error) {
        throw error
    }
}

const getRecruiterDetail = async (id:any): Promise<any> => {
    try {
        let response:any = await httpServices.get(`/recruiter/${id}`)
        if (response && typeof response?.data === 'string') { return [] }
        return response
    } catch (error) {
        throw error
    }
}

const getRecruiters = async (status:any): Promise<any> => {
    try {
        return await httpServices.get(`/recruiter?status=${status}`)
    } catch (error) {
        throw error
    }
}

const createRecruiter = async (data: any): Promise<any> => {
    try {
        return await httpServices.post('/recruiter', data)
    } catch (e) {
        throw e
    }
}

const updateRecruiter = async (id: any, data: any): Promise<any> => {
    try {
        return await httpServices.put(`/recruiter/${id}`, data)
    } catch (e) {
        throw e
    }
}

const deletedRecruiter = async (id: any): Promise<any> => {
    try {
        return await httpServices.remove(`/recruiter/${id}`)
    } catch (e) {
        throw e
    }
}

const restoreRecruiter = async (id: any): Promise<any> => {
    try {
        return await httpServices.get(`/restore-recruiter/${id}`)
    } catch (e) {
        throw e
    }
}

const getRecruiterTechnician = async (id:any): Promise<any> => {
    try {
        return await httpServices.get<any>(`/recruiter/technician/${id}`)
    } catch (e) {
        throw e
    }
}




export default {
    getRecruiter,
    getRecruiters,
    createRecruiter,
    updateRecruiter,
    deletedRecruiter,
    restoreRecruiter,
    getRecruiterDetail,
    getRecruiterTechnician
}