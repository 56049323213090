import React from 'react'
import { connect } from 'react-redux';
import { Redirect, Route } from 'react-router-dom'
import AuthService from '../../services/AuthService';

interface IProps {
  Component?: any;
  location?: any;
  exact?: any;
  path?: any;
}

class PrivateRoute extends React.Component<IProps> {


  render() {    

    const isLoggedIn = AuthService.isUserLogin();
    const { Component, location, ...props } = this.props;
    return (
      <Route
        {...props}
        render={(props) =>
          isLoggedIn ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/login", state: { from: props.location } }}
            />
          )
        }
      />
    );
  }
}


export default PrivateRoute;