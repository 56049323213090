import React, { Component } from 'react'
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Row,
  Col,
} from 'reactstrap';
import { Toaster, ToasterType } from '../Toaster';

interface IState {
  fileObj: any,
  docName: any,
  is_doc_name_required: boolean;
  is_doc_file_required: boolean;
}


interface IProps {
  modal: boolean,
  toggle: any,
  type: string,
  handleInputFile: any,
  isOfficialDoc?: boolean
}


export default class AddDocument extends Component<IProps, IState> {
  public state = {
    fileObj: undefined,
    docName: "",
    is_doc_name_required: false,
  is_doc_file_required: false
  };

  hanldeFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    let files = e.target.files;
    if (!files) {
      return;
    }

    if (this.props?.isOfficialDoc === true) {
      
      var regex = new RegExp("(.*?).(pdf|docx|doc)$");
      if (!regex.test(files[0]?.name)) {      
        e.preventDefault();
        Toaster("Only pdf|docx|doc file type accept", ToasterType.error);
        return
      }
    }
    this.setState({ fileObj: files[0], is_doc_file_required: false })
    
  };

  render() {
    const { modal, toggle, type, handleInputFile } = this.props;

    const handleInput = () => {
      
      let error = false;
      if (typeof this.state.docName === 'undefined' || this.state.docName === null || this.state.docName === "") {
        this.setState({ is_doc_name_required: true })
        error = true
      } else {
        this.setState({ is_doc_name_required: false });
      }

      if (
        typeof this.state.fileObj === "undefined" ||
        this.state.fileObj === null         
      ) {
        this.setState({ is_doc_file_required: true });
        error = true;
      } else {
        this.setState({ is_doc_file_required: false });
      }

      if (error) {
        Toaster("please fill * fields", ToasterType.info);
        return
      }
      
      handleInputFile(this.state.fileObj, this.state.docName);

      toggle();
    };

    const handleDocName = (e: any) => {
      this.setState({
        docName: e.target.value,
        is_doc_name_required:false
      });
    };

    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          backdrop="static"
          className="cust-modal max-wd-30vw modal-dialog-centered"
        >
          <ModalHeader toggle={toggle}>{type}</ModalHeader>
          <ModalBody>
            <Row>
              <Col lg={12} sm={12}>
                <FormGroup>
                  <Label for="docName">
                    Document Name<sup>*</sup>
                  </Label>
                  <Input
                    type="text"
                    name="docName"
                    onChange={handleDocName}
                    className={this.state.is_doc_name_required ? "bounce" : ""}
                    id="docName"
                    placeholder="Enter Document Name"
                  />
                </FormGroup>
              </Col>

              <Col lg={12} sm={12}>
                <FormGroup>
                  <Label for="docFile">
                    Upload Document<sup>*</sup>
                  </Label>
                  <div
                    className={
                      this.state.is_doc_file_required
                        ? "upload form-control bounce"
                        : "upload form-control"
                    }
                  >
                    <input
                      type="file"
                      onChange={this.hanldeFile}
                      name="upload"
                      id="fileUpload"
                    />
                    <span className="fileName">Select file..</span>
                    <input
                      type="button"
                      className="uploadButton"
                      value="Browse"
                    />
                  </div>
                </FormGroup>
              </Col>
            </Row>
          </ModalBody>
          <ModalFooter className="justify-content-center">
            <Button className="btn-size" onClick={handleInput}>
              Add
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
