import React from 'react';
import AuthForm from './AuthForm';
import AuthService from '../../services/AuthService';
import { Toaster, ToasterType } from '../component/Toaster';


export const STATE_LOGIN = "LOGIN";
export const STATE_SIGNUP = "SIGNUP";

// const mapStateToProps = (state: RootState) => {
//   const { isLoggedIn } = state.userLogin;
//   return { isLoggedIn };
// };

// const mapDispatcherToProps = (dispatch: Dispatch<UserAction>) => {
//   return {
//     userLoggedIn: (loggedIn: boolean) => {
//        ;
//       dispatch(setLoggedIn(loggedIn));
//     },
//   };
// };

// const connector = connect();
// type PropsFromRedux = ConnectedProps<typeof connector>;

interface IProps {
  history: { push(url: string): void };
  authState: string;  
}

// type ReduxType = ReturnType<typeof mapStateToProps> & ReturnType<typeof mapDispatcherToProps>;


class AuthPage extends React.Component<IProps> { 

  public handleAuthState = (authState: any) => {
    if (authState === STATE_LOGIN) {
      this.props.history.push("/");
    } else {
      this.props.history.push("/signup");
    }
  };

  redirectToDashboard() {
    window.location.replace("/");
  }

  handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {    
    e.preventDefault();
    const email = e.currentTarget.elements["email"]["value"];
    const password = e.currentTarget.elements["password"]["value"];
    if (email === "" || password === "") {
      return
    }
    try {
      let response = await AuthService.login(email, password);
      if (response) {
        if (
          typeof response.account_approvel !== "undefined" &&
          response.account_approvel !== null &&
          response.account_approvel !== ""
        ) {
          Toaster(response?.account_approvel, ToasterType.info);
          return;
        }
          this.redirectToDashboard();
      } else {
        Toaster("Email / Password incorrect",ToasterType.error)
      }
      
    } catch (error) {
              
    }
    

  };

  public render() {
    
    if (AuthService.isUserLogin()) {
      this.redirectToDashboard();
    }

    let element = document.querySelector(".cr-sidebar");
    if (element) {
      element.classList.remove("cr-sidebar--open");
    }

    return (
      <div className="loginWrapper">
        <div className="d-flex justify-content-between align-items-center h-100">
          <div className="loginLeftWrapper d-flex justify-content-between align-items-center flex-fill wd-40 h-100">
            <div className="loginContent ">
              <h2>Welcome to Quattris</h2>
              <p>
                We understand that our clients are looking for much more than a
                supplier when appointing a third party IT supplier to work on
                their critical system. From appointing the right project
                manager, to the handover or live date and beyond, Quattris take
                great care in planning every detail to ensure success.
              </p>
            </div>
          </div>
          <div className="loginRightWrapper d-flex justify-content-between align-items-center flex-fill wd-60  h-100">
            <div className="loginFormContent ">
              <AuthForm
                authState={this.props.authState}
                onChangeAuthState={this.handleAuthState}
                onSubmit={this.handleSubmit}
              />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AuthPage;
