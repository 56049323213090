import React, { Component } from "react";
import { connect } from "react-redux";
import PageSpinner from "../component/PageSpinner";
import Content from "./Content";


import SideBar from"./SideBar";
const Header = React.lazy(() => import("./Header"));
const Footer = React.lazy(() => import("./Footer"));


interface IProps {
  children?: any;
  breakpoint: any;
}

class MainLayout extends Component<
  IProps & React.HTMLAttributes<any>,
  {}
> {

  public static isSidebarOpen() {
    return document.querySelector(".cr-sidebar")!.classList.contains("cr-sidebar--open");
  }

  handleContentClick = (event: any) => {
    if (
      MainLayout.isSidebarOpen() &&
      (this.props.breakpoint === "xs" ||
        this.props.breakpoint === "sm" ||
        this.props.breakpoint === "md")
    ) {
      this.openSidebar("close");
    }
  };

  openSidebar(openOrClose: any) {
    if (openOrClose === "open") {
      return document
        .querySelector(".cr-sidebar")!
        .classList.add("cr-sidebar--open");
    }
    document.querySelector(".cr-sidebar")!.classList.remove("cr-sidebar--open");
  }

  render() {
    const { children, ...props } = this.props;    
      return (
        <main className="cr-app" style={{ position: "relative" }}>
          <SideBar />
          <React.Suspense fallback={<></>}>
            <Content
              Tag="div"
              className="container-fluid"
              onClick={this.handleContentClick}
            >
              <Header  />
              {children}
              <Footer />
            </Content>
          </React.Suspense>
        </main>
      );
  }
}

export default connect()(MainLayout)