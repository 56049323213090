import React from 'react'
import { GoogleMap, useJsApiLoader, Marker, InfoWindow, } from '@react-google-maps/api';
import { geocodeByLatLng } from 'react-google-places-autocomplete'
import markerpin from '../../assets/img/marker-pin.png'

const containerStyle = {
    width: '100%',
    height: '100%'
};


function AddressMapComponent(props) {
    const center = {
        lat: props?.center?.lat,
        lng: props?.center?.lng
    };    

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: "https://maps.googleapis.com/maps/api/js?key=AIzaSyAP_-iV2ekkQVU6Cp7pBrplEfLX5oWmbvs"
    })

    const [map, setMap] = React.useState(null)
    const [mapCenter, setmapCenter] = React.useState(center)

    const onLoad = React.useCallback(function callback(map) {
        setMap(map)
    }, [])

    const onUnmount = React.useCallback(function callback(map) {
        setMap(null)
    }, [])

    const onMarkerDragChange = async (e) => {        
        if (e?.latLng) {
            let place = await geocodeByLatLng(e?.latLng)
            if (place.length > 0) {
                props?.addressUpdate(place[0])                
            }
        }
    }


    return isLoaded && (
        
        <GoogleMap
            // onDragEnd={handleDrag}
            mapContainerStyle={containerStyle}
            center={props.center}
            zoom={9}
            onLoad={onLoad}
            onUnmount={onUnmount}                        
        >
            {
                props?.markers?.map((item, index) => {                    
                    return (
                        <Marker
                            key={index}
                            icon={markerpin}
                            draggable={true}                            
                            onDragEnd={onMarkerDragChange}
                            position={{
                                lat: parseFloat(item?.lat),
                                lng: parseFloat(item?.lng),
                            }}
                        >                            
                        </Marker>
                    )
                })
            }
        </GoogleMap>
    )
}


export default React.memo(AddressMapComponent);


