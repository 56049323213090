import { toast } from "material-react-toastify";
import "react-toastify/dist/ReactToastify.css";

export enum ToasterType {
  success,
  info,
  error,
  warning,
}

export const Toaster = (msg: any, type: ToasterType) => {
  if (type === ToasterType.success) {
    toast.success(msg, {
      position: "top-right",
    });
  } else if (type === ToasterType.info) {
    toast.info(msg, {
      position: "top-right",
    });
  } else if (type === ToasterType.warning) {
    toast.warning(msg, {
      position: "top-right",
    });
  } else if (type === ToasterType.error) {
    toast.error(msg, {
      position: "top-right",
    });
  }
};
