import React, { Component } from 'react'
import { Col, Input, Label, Row, Card, CardHeader, CardBody, Button } from 'reactstrap';
import CertificateService from '../../../../services/CertificateService';
import CheckboxGroup from "react-checkbox-group";

interface IState {
  certificates: any,
  certificateCheckList: any
}
interface IProps {
  handleCertifictate?: any,
  editCertificateData?: any
}
export default class AddTechnicianOemCertificates extends Component<IProps, IState>{
  public state = {
    certificates: [],
    certificateCheckList: []
  }
  componentDidUpdate(prev: any, next: any) {
    if (prev.editCertificateData !== this.props.editCertificateData) {
      if (this.props.editCertificateData) {
        this.setState({
          certificateCheckList:this.props.editCertificateData.map((e:any)=>{
            return(
              e.id
            )
          })
        })
      }
    }
  }
  async componentDidMount() {
    let certificateData: any = await CertificateService.getcertificates();
    
    if (certificateData) {
      this.setState({
        certificates: certificateData.data,
      });
    }
  }
  
  handleCertificate(e: any) {
    this.props.handleCertifictate(e)
    this.setState({
      certificateCheckList: e?.map((c: any) => {
        return c;
      })
    })
  }

  render() {
    return (
      <div className="tabInnerContent">
        <Row>
          {this.state.certificates?.map((item: any, idx:any) => {
            return (
              <Col key={idx} lg={6} sm={12} className="pb-2">
                <Card className="cardBox h-100">
                  <CardHeader className="d-flex justify-content-between align-items-center h-38 mb-0 br-0">
                    <div className="d-flex align-items-center">
                      <h2>{item.name}</h2>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="colList">
                      <ul className="p-0">
                        <CheckboxGroup
                          name="technicianCheckList"
                          value={this.state.certificateCheckList}
                          onChange={(e) => this.handleCertificate(e)}
                        >
                          {(Checkbox) => (
                            <>
                              {item.certificates?.map((t: any, index: any) => {
                                return (
                                  <li key={index}>
                                    <Label className="checkBox form-check-label">
                                      {t.name}
                                      <Checkbox value={t.id} />
                                      <span className="checkmark"></span>
                                    </Label>
                                  </li>
                                );
                              })}
                            </>
                          )}
                        </CheckboxGroup>
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            );
          })}

        </Row>
      </div>
    );
  }
}
