import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Button, Col, FormGroup, Input, Label, Row } from "reactstrap";
import { Toaster, ToasterType } from "../component/Toaster";
import { STATE_FORGOT_PASSWORD, STATE_RESET_PASSWORD } from "./AuthPasswordPage";

interface IProps {
  authState?: string;
  children?: any;
  onChangeAuthState?: any;
  onSubmit: any;
  token?:any;
}

interface IStates {
  email?: string;
  is_email_required: boolean;
  password?: string;
  is_password_required: boolean;
  confirmpassword?: string;  
  is_confirmpassword_required: boolean;  
}

class AuthPasswordForm extends Component<IProps, IStates> {
  public state = {
    email: "",
    is_email_required: false,
    password: "",
    is_password_required: false,
    confirmpassword: "",
    is_confirmpassword_required: false,
  };

  onClickforgotPassword = () => {
    if (
      typeof this.state.email === "undefined" ||
      this.state.email === null ||
      this.state.email === ""
    ) {
      Toaster("Please fill * field", ToasterType.error);
      this.setState({ is_email_required: true });
      return;
    }

    this.props.onSubmit({
      isForgot: true,
      email: this.state.email,
    });
  };

  onClickResetPassword = () => {
    let error = false;

    if (
      typeof this.state.email === "undefined" ||
      this.state.email === null ||
      this.state.email === ""
    ) {
      this.setState({ is_email_required: true });
      error = true;
    } else {
      this.setState({ is_email_required: false });
    }
   

    this.passwordMatchAndValidation(
      this.state.password,
      this.state.confirmpassword
    );


    this.props.onSubmit({
      isForgot: false,
      email: this.state.email,
      password: this.state.password,
      confirmpassword: this.state.confirmpassword,
    });
  };

  passwordMatchAndValidation(password: any, confirmPassword: any) {
    if (
      typeof password !== "undefined" &&
      password !== null &&
      password !== ""
    ) {      
      let regex = new RegExp(
        "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
      );
      if (!regex.test(password)) {
        Toaster(
          "Password must contain Uppercase lowercase numeric special character and minimun 8 characters",
          ToasterType.error
        );
        this.setState({
          is_password_required: true,
        });
        return;
      }

      if (
        typeof confirmPassword === "undefined" ||
        confirmPassword === null ||
        confirmPassword === ""
      ) {
        this.setState({ is_confirmpassword_required: true });
        return;
      }
      if (password !== confirmPassword) {
        // match confirm password
        Toaster("Password does not match", ToasterType.error);
        this.setState({
          is_password_required: true,
          is_confirmpassword_required: true,
        });
        return;
      }

      this.setState({ is_password_required: false, is_confirmpassword_required: false });
      return;
    } else {
      this.setState({ is_password_required: true });
      return;
    }
  }

  render() {
    const { authState, children } = this.props;

    return (
      <div className="formWrapper">
        <div>
          <Row>
            <Col lg={12} sm={12}>
              <FormGroup>
                <div className="titleWrapper">
                  {authState === STATE_FORGOT_PASSWORD ? (
                    <h3>Reset Password</h3>
                  ) : (
                    <h3>New Password</h3>
                  )}
                </div>
              </FormGroup>
            </Col>
          </Row>
          {/* LOGIN SECTION */}
          {authState === STATE_FORGOT_PASSWORD && (
            <React.Fragment>
              <Row>
                <Col lg={12} sm={12}>
                  <FormGroup>
                    <Label for={"email"}>{"Email"}</Label>
                    <Input
                      placeholder="Email"
                      name="email"
                      type="email"
                      className={this.state.is_email_required ? "bounce" : ""}
                      value={this.state.email}
                      onChange={(e) => {this.setState({email:e.target.value, is_password_required:false})}}
                    />
                  </FormGroup>
                </Col>
              </Row>
            </React.Fragment>
          )}
          {/* SIGNUP SECTION */}
          {authState === STATE_RESET_PASSWORD && (
            <React.Fragment>
              <Row>
                <Col lg={8} sm={12}>
                  <Row>
                    <Col lg={12} sm={12}>
                      <FormGroup>
                        <Label for={"email"}>{"Email"}</Label>
                        <Input
                          placeholder="Email"
                          name="Email"
                          type="email"
                          className={this.state.is_email_required ? "bounce": ""}
                          value={this.state.email}
                          onChange={(e: any) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <FormGroup>
                    <Label for={"Password"}>{"Password"}</Label>
                    <Input
                      placeholder={"Password"}
                      type="password"
                      name="Password"
                      className={this.state.is_password_required? "bounce": ""}
                      value={this.state.password}
                      onChange={(e: any) =>
                        this.setState({ password: e.target.value, is_password_required:false })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} sm={12}>
                  <FormGroup>
                    <Label for={"ConfirmPassword"}>{"Confirm Password"}</Label>
                    <Input
                      placeholder={"ConfirmPassword"}
                      type="password"
                      value={this.state.confirmpassword}
                      name="ConfirmPassword"
                      className={this.state.is_confirmpassword_required? "bounce": ""}
                      onChange={(e: any) =>
                        this.setState({ confirmpassword: e.target.value, is_confirmpassword_required:false })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </React.Fragment>
          )}

          <Row>
            <Col lg={12} sm={12}>
              <FormGroup>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="alreadyTextWrapper">
                    {authState === STATE_FORGOT_PASSWORD ? (
                      <span>
                        Remember account password?{" "}
                        <Link to="/login">Login</Link>
                      </span>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div className="actionButtonWrapper">
                    {authState === STATE_FORGOT_PASSWORD ? (
                      <Button
                        className="btn-size"
                        block
                        onClick={this.onClickforgotPassword}
                      >
                        Submit
                      </Button>
                    ) : (
                      <Button
                        className="btn-size"
                        block
                        onClick={this.onClickResetPassword}
                      >
                        Submit
                      </Button>
                    )}
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          {children}
        </div>
      </div>
    );
  }
}

export default AuthPasswordForm;
