import classnames from 'classnames';
import { Component } from 'react'
import Select from 'react-select';
import { Button, Col, FormGroup, Input, InputGroup, InputGroupAddon, Label, Row, Card, CardBody, CardHeader, } from 'reactstrap';
import { FaEdit } from '@react-icons/all-files/fa/FaEdit';
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';
import ConfirmationPopup from '../../../component/Modals/ConfirmationPopup';
import DocumentFormPopup from '../../../component/Modals/AddDocument';
import EmployementStatusService from '../../../../services/EmployementStatusService';
import RecruiterService from '../../../../services/RecruiterService';
import GeneralService from '../../../../services/GeneralService';
import { any } from 'glamor';
import { MdWarning } from 'react-icons/md';
import { Toaster, ToasterType } from '../../../component/Toaster';


interface IState {
  activeState: string;
  isVisible: boolean;
  currency: string;
  confirmationPopup: boolean;
  documentFormPopup: boolean;
  update: boolean;
  employeementStaus: any;
  recruiterList: any;
  currencyList: any;
  name: any;
  documentIndex?: any;

}

interface IProps {
  title?: any;
  availability?: any;
  handleChange?: any;
  handleDropdown?: any;
  handleInputFile?: any;
  files?: any;
  rate?: any;
  handleDelete?: any;
  invalidemployment_status?: any;
  invalidtitle?: any;
  invalidrecruiter?: any;
  invalidavailability?: any;
  invalidcan_contact?: any;
  invalidcurrency_rate?: any;
  employment_status?: any;
  can_contact?: any;
  recruiter?: any;
  currencyId?: any;
  type?: any;
  shareForm?:boolean;
}

export default class AddTechnicianOfficalStatus extends Component<IProps, IState> {
  public state = {
    activeState: "off",
    isVisible: false,
    currency: "",
    confirmationPopup: false,
    documentFormPopup: false,
    update: false,
    employeementStaus: [],
    recruiterList: [],
    currencyList: [],
    name: "",
    documentIndex:0,
  };

  async componentDidMount() {
    
    let employmentStatus: any =
      await EmployementStatusService.getEmploymentStatus();
    let recruiterList: any = await RecruiterService.getRecruiter();
    let currencyList: any = await GeneralService.getCurrencies();

    if (employmentStatus) {
      this.setState({
        employeementStaus: employmentStatus?.data?.map((e: any) => {
          return {
            value: e.id,
            label: e.name,
          };
        }),
      });
    }

    if (currencyList) {
      this.setState({
        currencyList: currencyList?.data?.map((e: any) => {
          return {
            value: e.id,
            label: e.name,
          };
        }),
      });
    }

    if (recruiterList && recruiterList?.data && recruiterList?.data?.length > 0) {
      this.setState({
        recruiterList: recruiterList?.data?.map((e: any) => {
          return {
            value: e.id,
            label: e.main_contact,
          };
        }),
      });
    }

    if (this.props.type === "recruiter") {
      this.setState({isVisible:true})
    }

  }

  componentWillUnmount() {
    
  }
  
  handleFile = (file: any, name: any) => {
    this.props.handleInputFile(file, name);
  };

  

  render() {
    const techEnagagment = [
      { value: "yes", label: "Yes" },
      { value: "no", label: "no" },
    ];

    const techTitle = [
      { value: "Network/IT Engineer", label: "Network/IT Engineer" },
      { value: "software", label: "Software" },
    ];

    const techAvailability = [
      { value: "24x7", label: "24 x 7" },
      {
        value: "onlyduringworkinghours",
        label: "Only During working hours",
      },
      { value: "weekends", label: "Weekends" },
      { value: "outofofficehours", label: "Out Of Office Hours" },
    ];

    const toggle = (tab: any) => {
      if (this.state.activeState !== tab) this.setState({ activeState: tab });
    };
    const handleDeleteFile = () => {
      this.props.handleDelete(this.state.documentIndex);
    };
    const {
      recruiter,
      handleChange,
      handleDropdown,
      files,
      invalidemployment_status,
      invalidtitle,
      invalidrecruiter,
      invalidavailability,
      invalidcan_contact,
      invalidcurrency_rate,
      employment_status,
      availability,
      can_contact,
      title,
      type,
      shareForm
    } = this.props;    
    return (
      <div className="tabInnerContent">
        <Row>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="techtype">
                Type<sup>*</sup>
              </Label>
              <div className="toggleInput">
                <InputGroup>
                  <InputGroupAddon addonType="prepend">
                    <Button
                      outline
                      className={classnames({
                        // active: this.state.activeState === "off",
                        active: type === "direct",
                      })}
                      onClick={(e) => {
                        let val = { value: "direct" };
                        handleDropdown(val, "type");
                        this.setState({ isVisible: false });
                        toggle("off");
                      }}
                    >
                      Direct
                    </Button>
                    <Button
                      outline
                      className={classnames({
                        // active: this.state.activeState === "on",
                        active: type !== "direct",
                      })}
                      onClick={(e) => {
                        let val = { value: "recruiter" };
                        handleDropdown(val, "type");
                        this.setState({ isVisible: true });
                        toggle("on");
                      }}
                    >
                      Via Recruiter
                    </Button>
                  </InputGroupAddon>
                  {this.state.isVisible && (
                    <Select
                      options={this.state.recruiterList}
                      name="recruiter"
                      id="recTitle"
                      placeholder="Select Recruiter"
                      isClearable
                      value={this.state.recruiterList.find(
                        (e: any) => e.value === recruiter
                      )}
                      className={invalidrecruiter ? "bounce" : ""}
                      onChange={(e) => handleDropdown(e, "recruiter")}
                    />
                  )}
                </InputGroup>
              </div>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="techTitle">
                Title<sup>*</sup>
              </Label>
              <Select
                options={techTitle}
                value={techTitle.find((e: any) => e.value === title)}
                name="techTitle"
                id="techTitle"
                placeholder="Select Technician Title"
                isClearable
                className={invalidtitle ? "bounce" : ""}
                onChange={(e) => handleDropdown(e, "title")}
              />
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="techStatus">
                Status<sup>*</sup>
              </Label>
              <Select
                options={this.state.employeementStaus}
                name="techStatus"
                id="techStatus"
                value={this.state.employeementStaus?.filter(
                  (e: any) => e.value === employment_status
                )}
                placeholder="Select Technician Status"
                isClearable
                className={invalidemployment_status ? "bounce" : ""}
                onChange={(e) => handleDropdown(e, "employment_status")}
              />
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="techAvailability">Availability</Label>
              <Select
                options={techAvailability}
                name="techAvailability"
                id="techAvailability"
                placeholder="Select Technician Availability"
                value={techAvailability?.filter(
                  (e: any) => e.value === availability
                )}
                isClearable
                className={invalidavailability ? "bounce" : ""}
                onChange={(e) => handleDropdown(e, "availability")}
              />
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="techEnagagment">
                Would you like to be contacted for fulltime engagements (3
                months or more) ?<sup>*</sup>
              </Label>
              <Select
                options={techEnagagment}
                name="can_contact"
                id="can_contact"
                value={techEnagagment?.filter(
                  (e: any) => e.value === can_contact
                )}
                placeholder="Select Engangments Option"
                isClearable
                className={invalidcan_contact ? "bounce" : ""}
                onChange={(e) => handleDropdown(e, "can_contact")}
              />
            </FormGroup>
          </Col>


{!shareForm && (



          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="workRate">FE Currency / Work Rate</Label>
              <InputGroup className="currencyField">
                <InputGroupAddon className="w-100" addonType="prepend">
                  <Select
                  
                    className={`wd-70 ${invalidcurrency_rate ? "bounce" : ""}`}
                    onChange={(e) => handleDropdown(e, "currency_rate")}
                    options={this.state.currencyList}
                    name="currency_rate"
                    value={this.state.currencyList?.filter(
                      (e: any) => e.value === this.props.currencyId
                    )}
                    id="currency"
                    placeholder="Select Currency"
                    isClearable
                  />
                  <Input
                    type="text"
                    value={this.props.rate}
                    className="wd-30 text-right"
                    onChange={handleChange}
                    name="rate"
                    id="workRate"
                    placeholder="20.00"
                    
                  />
                </InputGroupAddon>
              </InputGroup>
            </FormGroup>
          </Col>
    )}
          <Col lg={12} sm={12}>
            <FormGroup>
              <div className="docWrapper">
                <Card className="cardBox">
                  <CardHeader className="d-flex justify-content-between align-items-center h-38 mb-0 br-0">
                    <div className="d-flex align-items-center">
                      <h2>Doucuments</h2>
                    </div>
                    <div className="d-flex align-items-center">
                      <Button
                        onClick={() => {
                          this.setState({
                            documentFormPopup: true,
                            update: false,
                          });
                        }}
                        className="btn-size tableBtn"
                        outline
                      >
                        Add Document
                      </Button>
                    </div>
                  </CardHeader>
                  <CardBody>
                    <div className="colList">
                      <ul className="p-0 min-h-150">
                        {files && files.length > 0 ? (
                          files.map((e: any, index: any) => {
                            return (
                              <li key={index}>
                                <div className="docContent d-flex justify-content-between align-items-center w-100">
                                  <div className="docName">
                                    <span>{e.name}</span>
                                  </div>
                                  <div className="docAction d-flex justify-content-end align-items-center">
                                    {/* <Button outline onClick={() => { this.setState({ documentFormPopup: true, update: true }) }}><FaEdit></FaEdit></Button> */}
                                    <Button
                                      outline
                                      onClick={() => {
                                        this.setState({
                                          confirmationPopup: true,
                                          name: e.name,
                                          documentIndex: index,
                                        });
                                      }}
                                      className="ml-2"
                                    >
                                      <FaTrash />
                                    </Button>
                                  </div>
                                </div>
                              </li>
                            );
                          })
                        ) : (
                          <div className="noData">
                            <MdWarning size={32} />
                            <p>No File Available</p>
                          </div>
                        )}
                      </ul>
                    </div>
                  </CardBody>
                </Card>
              </div>
            </FormGroup>
          </Col>
        </Row>
        <DocumentFormPopup
          modal={this.state.documentFormPopup}
          handleInputFile={this.handleFile}
          toggle={() => this.setState({ documentFormPopup: false })}
          type={this.state.update ? "Update Document" : "Add Document"}
          isOfficialDoc={true}
        />
        <ConfirmationPopup
          modal={this.state.confirmationPopup}
          toggle={() => this.setState({ confirmationPopup: false })}
          type="Documents"
          handleDelete={handleDeleteFile}
          icon={<FaTrash />}
          message={"Are you sure you want to remove this Document?"}
        />
      </div>
    );
  }
}
