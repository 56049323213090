import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { FaTrash } from "@react-icons/all-files/fa/FaTrash";
import { FaRedoAlt } from "@react-icons/all-files/fa/FaRedoAlt";
import ProjectService from "../../../services/ProjectService";
import { Toaster, ToasterType } from "../Toaster";

interface IProps {
  modal: boolean;
  toggle: any;
  type: string;
  message: string;
  icon: any;
  handleDelete?: any;
}

export default class ConfirmationPopup extends Component<IProps, {}> {
  
  render() {
    
    const handleDeleteFile = () => {
      this.props.toggle();
      this.props.handleDelete();
    };

    const { modal, toggle, type, message, icon, handleDelete } = this.props;
    return (
      <div>
        <Modal
          isOpen={modal}
          toggle={toggle}
          backdrop="static"
          className="confirmation-modal max-wd-30vw modal-dialog-centered"
        >
          {/* <ModalHeader>{`Delete ${type}`}</ModalHeader> */}
          <ModalBody className="text-center pb-2">
            <div className="iconBox mb-3">{icon}</div>
            <p>{message}</p>
          </ModalBody>
          <ModalFooter className="pt-2">
            <Button className="btn-size" onClick={handleDeleteFile}>
              Yes
            </Button>
            <Button className="btn-size" onClick={toggle}>
              No
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}
