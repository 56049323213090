import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { STATE_LOGIN, STATE_SIGNUP } from './AuthPage';

interface IProps {
  authState?: string;
  children?: any;
  onChangeAuthState?: any;
  onSubmit: any;
}

interface IStates {
  email?: string;
  password?: string;
  confirmpassword?: string;
  company?: string;
  phone?: string;
}

class AuthForm extends Component<IProps, IStates> {
  
  public state = {
    email: "",
    password: "",
    confirmpassword: "",
    company: "",
    phone: "",
  };

  render() {
    const { authState, children } = this.props;

    return (
      <div className="formWrapper">
        <form onSubmit={this.props.onSubmit}>
          <Row>
            <Col lg={12} sm={12}>
              <FormGroup>
                <div className="titleWrapper">
                  {authState === STATE_LOGIN ? <h3>Login</h3> : <h3>Signup</h3>}
                </div>
              </FormGroup>
            </Col>
          </Row>
          {/* LOGIN SECTION */}
          {authState === STATE_LOGIN && (
            <React.Fragment>
              <Row>
                <Col lg={12} sm={12}>
                  <FormGroup>
                    <Label for={"email"}>{"Email"}</Label>
                    <Input
                      placeholder="Email"
                      name="email"
                      type="email"                                            
                    />
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12} sm={12}>
                  <FormGroup>
                    <Label for={"Password"}>{"Password"}</Label>
                    <Input
                      placeholder={"Password"}
                      type="password"
                      name="password"                      
                    />
                  </FormGroup>
                </Col>
              </Row>
            </React.Fragment>
          )}
          {/* SIGNUP SECTION */}
          {authState === STATE_SIGNUP && (
            <React.Fragment>
              <Row>
                <Col lg={8} sm={12}>
                  <Row>
                    <Col lg={12} sm={12}>
                      <FormGroup>
                        <Label for={"company"}>{"Company"}</Label>
                        <Input
                          type="text"
                          placeholder="Enter Company Name"
                          value={this.state.company}
                          onChange={(e: any) =>
                            this.setState({ company: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={12} sm={12}>
                      <FormGroup>
                        <Label for={"email"}>{"Email"}</Label>
                        <Input
                          placeholder="Email"
                          name="Email"
                          type="email"
                          value={this.state.email}
                          onChange={(e: any) =>
                            this.setState({ email: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                    <Col lg={12} sm={12}>
                      <FormGroup>
                        <Label for={"phone"}>{"Phone "}</Label>
                        <Input
                          type="text"
                          placeholder="Enter Phone Number"
                          value={this.state.phone}
                          onChange={(e: any) =>
                            this.setState({ phone: e.target.value })
                          }
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col lg={6} sm={12}>
                  <FormGroup>
                    <Label for={"Password"}>{"Password"}</Label>
                    <Input
                      placeholder={"Password"}
                      type="password"
                      name="Password"
                      value={this.state.password}
                      onChange={(e: any) =>
                        this.setState({ password: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
                <Col lg={6} sm={12}>
                  <FormGroup>
                    <Label for={"ConfirmPassword"}>{"Confirm Password"}</Label>
                    <Input
                      placeholder={"ConfirmPassword"}
                      type="password"
                      value={this.state.confirmpassword}
                      name="ConfirmPassword"
                      onChange={(e: any) =>
                        this.setState({ confirmpassword: e.target.value })
                      }
                    />
                  </FormGroup>
                </Col>
              </Row>
            </React.Fragment>
          )}
          <Row>
            <Col lg={12} sm={12}>
              <FormGroup>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="rememberMe">
                    <Label className="checkBox" check>
                      <Input type="checkbox" />{" "}
                      {authState === STATE_SIGNUP
                        ? "Agree the terms and policy"
                        : "Remember me"}
                      <span className="checkmark"></span>
                    </Label>
                  </div>
                  <div className="forgetPassword">
                    {authState === STATE_LOGIN && (
                      <Link to="/ForgotPassword">Forgot Password</Link>
                    )}
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col lg={12} sm={12}>
              <FormGroup>
                <div className="d-flex justify-content-between align-items-center">
                  <div className="alreadyTextWrapper">
                    {authState !== STATE_LOGIN ? (
                      <span>
                        Already have account? <Link to="/login">Login</Link>
                      </span>
                    ) : (
                      <span>
                        Don't have an account? <Link to="/signup">Signup</Link>
                      </span>
                    )}
                  </div>
                  <div className="actionButtonWrapper">
                    {authState === STATE_LOGIN ? (                      
                        <Button
                          className="btn-size"
                          block
                          type="submit"
                        >
                          Login
                        </Button>                      
                    ) : (
                      <Button className="btn-size" block onClick={() => {}}>
                        Signup
                      </Button>
                    )}
                  </div>
                </div>
              </FormGroup>
            </Col>
          </Row>
          {children}
        </form>
      </div>
    );
  }
}

export default AuthForm