import classnames from 'classnames';
import React, { Component } from 'react'
import { Link } from 'react-router-dom';
import { Button, Card, CardBody, CardFooter, Col, FormGroup, Input, Label, Nav, NavItem, NavLink, Row, TabContent, Table, TabPane } from 'reactstrap';

import TechnicianService from '../../../../services/TechnicianService';
import CheckboxGroup from "react-checkbox-group";
import { isThisSecond } from 'date-fns/esm';

interface IState {
    toolkitArray: any;
    toolKitCheckList: any
}
interface IProps {
    handleToolkit: any,
    toolkitEdit?: any
}
export default class AddTechnicianToolKit extends Component<IProps, IState> {

    public state = {
        toolkitArray: [],
        toolKitCheckList: []
    };
    async componentDidMount() {
        let toolkitData: any = await this.getToolKitData();
        if (toolkitData) {
            this.setState({ toolkitArray: toolkitData.data })
        }
    }
    async componentDidUpdate(prev: any, next: any) {
        if (prev.toolkitEdit !== this.props.toolkitEdit) {
               
            if (this.props.toolkitEdit) {
                await this.setState({
                    toolKitCheckList: this.props.toolkitEdit?.map((e: any) => {
                        return e.id
                    })
                })
                this.props.handleToolkit(this.state.toolKitCheckList);
            }

        }
    }
    getToolKitData = async () => {
        return await TechnicianService.getToolKit();
    }
    handletoolkit = (event: any) => {
        this.setState({
            toolKitCheckList: event?.map((c: any) => {
                return c;
            })
        })
        this.props.handleToolkit(event)
    }
    render() {
        return (
            <div className="tabInnerContent">
                <Row>
                    <Col lg={12} sm={12}>
                        <div className="colList">
                            <ul className="p-0">
                                {/* {this.state.toolkitArray.length > 0 && this.state.toolkitArray.map((e: any) => {
                  return (
                    <li>
                      <Label className="checkBox" check>
                        {e.name}
                        <Input type="checkbox" />
                        <span className="checkmark"></span>
                      </Label>
                    </li>
                  )
                })} */}
                                <CheckboxGroup
                                    name="technicianCheckList"
                                    value={this.state.toolKitCheckList}
                                    onChange={(e) => {
                                           
                                        this.handletoolkit(e)
                                    }}
                                >
                                    {(Checkbox) => (
                                        <>
                                            {this.state.toolkitArray?.map((t: any,index:any) => {
                                                return (
                                                    <li key={index}>
                                                        <Label className="checkBox form-check-label">
                                                            {t.name}
                                                            <Checkbox value={t.id} />
                                                            <span className="checkmark"></span>
                                                        </Label>
                                                    </li>
                                                );
                                            })}
                                        </>
                                    )}
                                </CheckboxGroup>
                            </ul>
                        </div>
                    </Col>
                </Row>
            </div>
        );
    }
}
