const colors = {
    black0: '#1d1d1d', 
    black1: '#000000',
    gray0: '#fafafa', 
    gray1: '#f1f1f1', 
    semiGray: '#F6F6F6',
    gray2: '#d3dbde', 
    gray3: '#9F9F9F', 
    gray4: '#959595', 
    gray5: '#5D5D5D', 
    gray6: '#A59FA7', 
    gray7: '#F9FAFA',
    menu: '#3a424b',
    white: '#ffffff',
    white1: '#f0f3f4',
    red1: '#C47272',
    blue0: '#EBF7FF', 
    blue1: '#37B2E0', 
    blue2: '#42A6E6', 
    blue3: '#3296D6', 
    blue4: '#289ecb', 
    blue5: '#0056b3', 
    blue6: '#4D5762', 
    green0: '#71BA90',
    green1: '#F5FFFB',
    green2: '#23B376',
    purple0: '#BD83E8',
    purple1: '#9B71BA',
    purple2: '#8C4FB9', 
    orange0: '#FFF7E6',
    orange1: '#FFC646',
    orange2: '#F5AA00', 
    transparent: 'transparent',
    yellow: '#F5AA01', 

    bootstrap: {
        success0: '#d4edda',
        success1: '#c3e6cb',
        success2: '#155724',
        warning0: '#fff3cd',
        warning1: '#ffeeba',
        warning2: '#856404',
        danger0: '#f8d7da',
        danger1: '#f5c6cb',
        danger2: '#721c24',
        info0: '#ffffff',
        info1: '#d3dbde',
        info2: '#5D5D5D',
        outline: '#80bdff',
        boxShadow: 'rgba(0,123,255,.25)'
    }
};


export default colors