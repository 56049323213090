import React from "react";
import bn from "../../utility/bemnames";


const bem = bn.create("page");

export interface IProps {
  title?: string;
  breadcrumbs?: any[];
  className?: any;
  children?: any;  
}

class Page extends React.PureComponent<IProps> {
  public render() {
    const { children, className, title, breadcrumbs, ...props } = this.props;
    const classes = bem.b("m15", className);
    return (
      <div className={classes} {...props}>
        <div className={bem.e("header")}>
          {title && typeof title === "string" ? <h1>{title}</h1> : title}
        </div>
        {children}
      </div>
    );
  }
}

export default Page;
