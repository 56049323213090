import React from "react";
import { Component } from "react";
import { BrowserRouter as Router, Route,HashRouter, BrowserRouter, Switch } from 'react-router-dom'
import AppRoutes from "./AppRoutes";
import { ToastContainer } from 'material-react-toastify';
import 'material-react-toastify/dist/ReactToastify.css';
import AuthPage, { STATE_LOGIN, STATE_SIGNUP } from "./Auth/AuthPage";
import MainLayout from "./Layout/MainLayout";
import { bindActionCreators } from "redux";
import GeneralService from "../services/GeneralService";
import { fetchPermission } from "../redux/actions/permissionAction";
import { connect } from "react-redux";
import ShareTechnician from "./views/Technician/AddTechnician";
import AuthPasswordPage, { STATE_FORGOT_PASSWORD, STATE_RESET_PASSWORD } from "./Auth/AuthPasswordPage";

interface IProps {
  getPermission: any;
}

class App extends Component<IProps, {}> {
  getBasename = () => {
    return `/${process.env.PUBLIC_URL}`;
  };

  componentDidMount() {
    
  }

  public render() {
    return (
      <>
        <HashRouter>
          <ToastContainer />
          <Switch>
            <Route
              path="/signup"
              component={(props: any) => (
                <AuthPage {...props} authState={STATE_SIGNUP} />
              )}
            />
            <Route
              path="/login"
              component={(props: any) => (
                <AuthPage {...props} authState={STATE_LOGIN} />
              )}
            />
            <Route
              exact
              path="/forgotPassword"
              component={(props: any) => (
                <AuthPasswordPage {...props} authState={STATE_FORGOT_PASSWORD} />
              )}
            />
            <Route
              exact
              path="/resetPassword/:token"
              component={(props: any) => (
                <AuthPasswordPage {...props} authState={STATE_RESET_PASSWORD} />
              )}
            />
            <Route
              path="/shareForm/:token"
              component={(props: any) => <ShareTechnician {...props} />}
            />

            <MainLayout breakpoint={"xl"}>
              <AppRoutes />
            </MainLayout>
          </Switch>
        </HashRouter>
      </>
    );
  }
}


const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators(
    {
      getPermission: GeneralService.getPermissions,
      fetchPermissions: fetchPermission,
    },
    dispatch
  );

  const mapStateToProps = (state: any) => ({
    permissions: state.permission.permissions,
  });

export default connect(mapStateToProps, mapDispatchToProps)(App);
