import React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { Route } from 'react-router-dom';
import { RootState } from '../redux';
import NotFound from './component/NotFound';
import PrivateRoute from './component/PrivateRoute';


const Dashboard = React.lazy(() => import('./views/Dashboard/Dashboard'));
const AddTechnician = React.lazy(() => import('./views/Technician/AddTechnician'));
const EditTechnician = React.lazy(() => import('./views/Technician/EditTechnician'));
const Technician = React.lazy(() => import('./views/Technician/Technician'));
const Tickets = React.lazy(() => import('./views/Tickets/Tickets'));
const ViewTechnician = React.lazy(() => import("./views/Technician/ViewTechnician"));
const Projects = React.lazy(() => import('./views/Projects/Projects'));
const ProjectDetails = React.lazy(() => import('./views/Projects/ProjectDetails'));
const Users = React.lazy(() => import('./views/UserManagement/Users'));
const LoginApprovals = React.lazy(() => import('./views/UserManagement/LoginApprovals'));
const Roles = React.lazy(() => import('./views/UserManagement/Roles'));
const RoleDetail = React.lazy(() => import('./views/UserManagement/RoleDetail'));
const Permissions = React.lazy(() => import('./views/UserManagement/Permissions'));
const Recruiters = React.lazy(() => import('./views/Recruiters/Recruiters'));
const RecruiterDetails = React.lazy(() => import('./views/Recruiters/RecruiterDetails'));
const Partners = React.lazy(() => import('./views/Partners/Partners'));
const SystemSettings = React.lazy(() => import('./views/Settings/SystemSettings/SystemSettings'));
const GeneralSettings = React.lazy(() => import('./views/Settings/GeneralSettings/GeneralSettings'));
const Reports = React.lazy(() => import('./views/Reports/Reports'));
const ThemeSettings = React.lazy(() => import("./views/Settings/GeneralSettings/Sections/ThemeSettings"));

class AppRoutes extends React.Component {
  render() {
    return (
      <>
        <PrivateRoute exact path="/" Component={Dashboard} />
        <PrivateRoute exact path="/Technician" Component={Technician} />
        <PrivateRoute exact path="/AddTechnician" Component={AddTechnician} />
        <PrivateRoute
          exact
          path="/EditTechnician/:id"
          Component={AddTechnician}
        />
        <PrivateRoute
          exact
          path="/ViewTechnician/:id"
          Component={ViewTechnician}
        />
        <PrivateRoute exact path="/Tickets" Component={Tickets} />
        <PrivateRoute exact path="/Projects" Component={Projects} />
        <PrivateRoute
          exact
          path="/ProjectDetails/:id"
          Component={ProjectDetails}
        />
        <PrivateRoute exact path="/Users" Component={Users} />
        <PrivateRoute exact path="/LoginApprovals" Component={LoginApprovals} />
        <PrivateRoute exact path="/Roles" Component={Roles} />
        <PrivateRoute exact path="/Role/:id" Component={RoleDetail} />
        <PrivateRoute exact path="/Role" Component={RoleDetail} />
        <PrivateRoute exact path="/Permissions" Component={Permissions} />
        <PrivateRoute exact path="/Recruiters" Component={Recruiters} />
        <PrivateRoute
          exact
          path="/RecruiterDetails/:id"
          Component={RecruiterDetails}
        />
        <PrivateRoute exact path="/Reports" Component={Reports} />
        <PrivateRoute exact path="/Partners" Component={Partners} />
        <PrivateRoute exact path="/SystemSettings" Component={SystemSettings} />
        <PrivateRoute
          exact
          path="/GeneralSettings"
          Component={GeneralSettings}
        />
        <PrivateRoute exact path="/ThemeColor" Component={ThemeSettings} />

        {/* <Route component={NotFound} /> */}
      </>
    );
  }
}

export default AppRoutes
