import { ICountry } from "../models/ICountry"
import httpServices from "./Helper/HttpService"


const getSkills = async ():Promise<any> => {
    try {
        let response: any = await httpServices.get("/sf_skill")
        if (response && typeof response.data === 'string') { return [] }
        return response

    } catch (error) {
        throw error
    }
}

const getSkillSet = async (): Promise<any> => {
    
    try {
        let response: any = await httpServices.get("/sf_skillset")
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}


// Skill Category
const getSkillCategoryList = async (): Promise<any> => {
    
    try {
        let response: any = await httpServices.get("/categories")
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const createSkillCategory = async (data: any): Promise<any> => {

    try {
        let response: any = await httpServices.post(`/categories`,data)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const updateSkillCategory = async (id: any,data:any): Promise<any> => {

    try {
        let response: any = await httpServices.put(`/categories/${id}`,data)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const deleteSkillCategory = async (id: any): Promise<any> => {

    try {
        let response: any = await httpServices.get(`/delete-category/${id}`)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const restoreSkillCategory = async (id: any): Promise<any> => {

    try {
        let response: any = await httpServices.get(`/restore-category/${id}`)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

// skills
const getSkillList = async (): Promise<any> => {

    try {
        let response: any = await httpServices.get("/skill")
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const createSkill = async (data: any): Promise<any> => {

    try {
        let response: any = await httpServices.post(`/skill`, data)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const updateSkill = async (id: any, data: any): Promise<any> => {

    try {
        let response: any = await httpServices.put(`/skill/${id}`, data)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const deleteSkill = async (id: any): Promise<any> => {

    try {
        let response: any = await httpServices.get(`/delete-skill/${id}`)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const restoreSkill = async (id: any): Promise<any> => {

    try {
        let response: any = await httpServices.get(`/restore-skill/${id}`)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

// skill Set
const getSkillSetList = async (): Promise<any> => {

    try {
        let response: any = await httpServices.get("/skillset")
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const createSkillSet = async (data: any): Promise<any> => {

    try {
        let response: any = await httpServices.post(`/skillset`, data)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const updateSkillSet = async (id: any, data: any): Promise<any> => {

    try {
        let response: any = await httpServices.put(`/skillset/${id}`, data)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const deleteSkillSet = async (id: any): Promise<any> => {

    try {
        let response: any = await httpServices.get(`/delete-skillset/${id}`)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const restoreSkillSet = async (id: any): Promise<any> => {

    try {
        let response: any = await httpServices.get(`/restore-skillset/${id}`)
        if (response && typeof response?.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

export default {
    getSkills,
    getSkillSet,
    // category
    getSkillCategoryList,
    createSkillCategory,
    updateSkillCategory,
    deleteSkillCategory,
    restoreSkillCategory,
    // Skill
    getSkillList,
    createSkill,
    updateSkill,
    deleteSkill,
    restoreSkill,
    // Skill Set
    getSkillSetList,
    createSkillSet,
    updateSkillSet,
    deleteSkillSet,
    restoreSkillSet
}