import { FETCH_PERMISSION } from '../actions/permissionAction';

const initialState = {
    pending: false,
    permissions: [],
}

function permissionReducer(state = initialState, action: any) {
    switch (action.type) {       
        case FETCH_PERMISSION:
            return {
                ...state,
                permissions: action.permissions
            }
        default:
            return state;
    }
}

export default permissionReducer
