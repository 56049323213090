import { ICity } from "../models/ICity"
import httpServices from "./Helper/HttpService"


const getCityByCountry = async (country_id:any):Promise<any> => { 
    try {
        return await httpServices.post("/sf_cities", { country_id: country_id})
    } catch (error) {
        throw error
    }
}

const getCityByCountries = async (country_id: any[]): Promise<any> => {
    try {
        return await httpServices.post("/cities-by-country", { country_data: country_id })
    } catch (error) {
        throw error
    }
}

// cities
const getCityList = async (): Promise<any> => {
    try {
        let response: any = await httpServices.get("/cities");
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const createCity = async (data: any): Promise<any> => {
    try {
        let response: any = await httpServices.post(`/cities`, data);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const updateCity = async (id: any, data: any): Promise<any> => {
    try {
        let response: any = await httpServices.put(
            `/cities/${id}`,
            data
        );
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const deleteCity = async (id: any): Promise<any> => {
    try {
        let response: any = await httpServices.get(`/delete-city/${id}`);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const restoreCity = async (id: any): Promise<any> => {
    try {
        let response: any = await httpServices.get(`/restore-city/${id}`);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};



export default {
    getCityByCountry,
    getCityByCountries,
    // cities
    getCityList,
    createCity,
    updateCity,
    deleteCity,
    restoreCity,
}