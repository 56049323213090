import { ICountry } from "../models/ICountry"
import httpServices from "./Helper/HttpService"


const getCountry = async ():Promise<ICountry[]> => {
    try {
        return await httpServices.get("/sf_countries")
    } catch (error) {
        throw error
    }
}

const getRegionCountries = async (region:any): Promise<ICountry[]> => {
    try {
        return await httpServices.post("/country-by-region", { "region_id":region})
    } catch (error) {
        throw error
    }
}

//  countries

const getCountryList = async (): Promise<any> => {
    try {
        let response: any = await httpServices.get("/countries");
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const createCountry = async (data: any): Promise<any> => {
    try {
        let response: any = await httpServices.post(`/countries`, data);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const updateCountry = async (id: any, data: any): Promise<any> => {
    try {
        let response: any = await httpServices.put(
            `/countries/${id}`,
            data
        );
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const deleteCountry = async (id: any): Promise<any> => {
    try {
        let response: any = await httpServices.get(`/delete-country/${id}`);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

const restoreCountry = async (id: any): Promise<any> => {
    try {
        let response: any = await httpServices.get(`/restore-country/${id}`);
        if (response && typeof response?.data === "string") {
            return [];
        }
        return response;
    } catch (error) {
        throw error;
    }
};

export default {
    getCountry,
    getRegionCountries,
    // countries
    getCountryList,
    createCountry,
    updateCountry,
    deleteCountry,
    restoreCountry,
}