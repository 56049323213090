export const FETCH_technician = 'FETCH_technician';
export const FETCH_technician_Pending = 'FETCH_technician_Pending';



export function fetchtechnician(
    technician: any,
    fullname?: any,
    address?: any,
    radius?: any,
    skillset?: any,
    language?: any,
    ne_lat?: any,
    ne_lng?: any,
    sw_lat?: any,
    sw_lng?: any,
    status?: any) {
    return {
        type: FETCH_technician,
        technician: technician,
        fullname: fullname,
        address: address,
        radius: radius,
        skillset: skillset,
        language: language,
        ne_lat: ne_lat,
        ne_lng: ne_lng,
        sw_lat: sw_lat,
        sw_lng: sw_lng,
        status: status        
    }
}

export function fetchtechnicianPending() {
    return {
        type: FETCH_technician_Pending,
        technician: []
    }
}

