import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';

import alertReducer from './reducers/alertReducer';
import permissionReducer from './reducers/permissionReducer';
import productsReducer from './reducers/technicianReducer';
import ticketReducer from './reducers/ticketReducer';

const rootReducer = combineReducers({
    alert: alertReducer,
    product:productsReducer,
    permission: permissionReducer,
    ticket: ticketReducer
});

const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
);

export type RootState = ReturnType<typeof rootReducer>;
export type AppDispatch = typeof store.dispatch

export default store;