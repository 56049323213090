export const FETCH_PERMISSION = 'FETCH_PERMISSION';



export function fetchPermission(permissions: any[]) {
    return {
        type: FETCH_PERMISSION,
        permissions: permissions
    }
}

