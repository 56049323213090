import {FETCH_technician,FETCH_technician_Pending} from '../actions/technicianAction';

const initialState = {
    pending: false,
    isReset: true,
    fullname: "",
    address: "london",
    radius: 25,
    skillset: null,
    language: null,
    ne_lat: 51.509865,
    ne_lng: -0.118092,
    sw_lat: null,
    sw_lng: null,
    status: "active",
    technician: [], 
}

 function productsReducer(state = initialState, action:any) {
    switch(action.type) {
        case FETCH_technician_Pending: 
        return {
            ...state,
            pending: true
        }
        case FETCH_technician:
            return {
                ...state,
                technician: action.technician,
                isReset: false,
                fullname: action.fullname,
                address: action.address,
                radius: action.radius,
                skillset: action.skillset,
                language: action.language,
                ne_lat: action.ne_lat,
                ne_lng: action.ne_lng,
                sw_lat: action.sw_lat,
                sw_lng: action.sw_lng,
                status: action.status
            }
        default: 
            return state;
    }
}

export const getTechnician = (state:any) => state.technician;

export default productsReducer
