import { ICountry } from "../models/ICountry"
import httpServices from "./Helper/HttpService"


const getcertificates= async ():Promise<any[]> => {
    try {
        let response: any = await httpServices.get("/sf_certificates");
        if (response && typeof response.data === 'string') {
            return []
        }
        return response
    } catch (error) {
        throw error
    }
}

const getCertificateTypes = async (): Promise<any[]> => {
  try {
    let response: any = await httpServices.get("/certificates");
    if (response && typeof response.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};

// certificate types
const getCertificateTypeList = async (): Promise<any> => {
  try {
    let response: any = await httpServices.get("/certificate_types");
    if (response && typeof response?.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const createCertificateType = async (data: any): Promise<any> => {
  try {
    let response: any = await httpServices.post(`/certificate_types`, data);
    if (response && typeof response?.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateCertificateType = async (id: any, data: any): Promise<any> => {
  try {
    let response: any = await httpServices.put(`/certificate_types/${id}`, data);
    if (response && typeof response?.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteCertificateType = async (id: any): Promise<any> => {
  try {
    let response: any = await httpServices.get(`/delete-certificate-type/${id}`);
    if (response && typeof response?.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const restoreCertificateType = async (id: any): Promise<any> => {
  try {
    let response: any = await httpServices.get(`/restore-certificate-type/${id}`);
    if (response && typeof response?.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};
// certificates
const getCertificateList = async (): Promise<any> => {
  try {
    let response: any = await httpServices.get("/certificates");
    if (response && typeof response?.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const createCertificate = async (data: any): Promise<any> => {
  try {
    let response: any = await httpServices.post(`/certificates`, data);
    if (response && typeof response?.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const updateCertificate = async (id: any, data: any): Promise<any> => {
  try {
    let response: any = await httpServices.put(
      `/certificates/${id}`,
      data
    );
    if (response && typeof response?.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const deleteCertificate = async (id: any): Promise<any> => {
  try {
    let response: any = await httpServices.get(`/delete-certificate/${id}`);
    if (response && typeof response?.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};

const restoreCertificate = async (id: any): Promise<any> => {
  try {
    let response: any = await httpServices.get(`/restore-certificate/${id}`);
    if (response && typeof response?.data === "string") {
      return [];
    }
    return response;
  } catch (error) {
    throw error;
  }
};

export default {
  getcertificates,
  getCertificateTypes,
  // certificate type,
  getCertificateTypeList,
  createCertificateType,
  updateCertificateType,
  deleteCertificateType,
  restoreCertificateType,
  // certificate
  getCertificateList,
  createCertificate,
  updateCertificate,
  deleteCertificate,
  restoreCertificate,
};