import React, { Component } from 'react'
import Select from 'react-select';
import { Button, Card, CardBody, CardHeader, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import GoogleMapReact from 'google-map-react'
import GooglePlacesAutocomplete, { geocodeByPlaceId } from 'react-google-places-autocomplete';
import AutoComplete from '../../../maps/AutoComplete'
import AddressMap from "../../../maps/AddressMap";
import { MdWarning } from 'react-icons/md';
import { FaTrash } from '@react-icons/all-files/fa/FaTrash';
import ConfirmationPopup from "../../../component/Modals/ConfirmationPopup";
import DocumentFormPopup from "../../../component/Modals/AddDocument";

interface IProps {
  handleChange?: any;
  handleDropdown?: any;
  countryArray?: any;
  cityArray?: any;
  handleAddress?: any;
  invalidcity?: any;
  invalidemail?: any;
  invalidcountry?: any;
  invalidphone?: any;
  invalidaddress?: any;
  invalidwhats_app?: any;
  invalidprofile_link?: any;
  invalidskype?: any;
  email?: any;
  skype?: any;
  profile_link?: any;
  whats_app?: any;
  phone?: any;
  country?: any;
  address?: any;
  cityRef?: any;
  countryRef?: any;
  city?: any;
  near_by_area?: any[];
  work_permit?: any;
  latitude?: any;
  longitude?: any;
  files?: any;
  handleWorkPermitFile?: any;
  handleWorkPermitDeleteFile?: any;
  shareForm?:boolean;
}
interface IState {
  lat: number;
  lng: number;
  confirmationPopup: boolean;
  documentFormPopup: boolean;
  name?: any;
  documentIndex?: any;
}
export default class AddTechnicianContactDetails extends Component<IProps, IState> {
  public state = {
    lat: 0,
    lng: 0,
    confirmationPopup: false,
    documentFormPopup: false,
    name: "",
    documentIndex: 0,
  };

  _onChange = async (e: any) => {
    let address = e.formatted_address;
    let place_id = e?.place_id;
    let geocode = await geocodeByPlaceId(place_id);
    this.setState({ lat: 36.1699412 });
    this.setState({ lng: -115.1398296 });
    this.props.handleAddress(
      geocode[0]?.geometry?.location?.lat(),
      geocode[0]?.geometry?.location?.lng(),
      address
    );
  };

  onChangeNearBy = async (e: any) => {
    let address = e;
    let place_id = e.value.place_id;
    let geocode = await geocodeByPlaceId(place_id);
  };

  handleFile = (file: any, name: any) => {
    this.props.handleWorkPermitFile(file, name);
  };

  handleDeleteFile = () => {
    this.props.handleWorkPermitDeleteFile(this.state.documentIndex);
  };

  render() {
    const permit = [
      { value: "yes", label: "yes" },
      { value: "no", label: "no" },
    ];

    const dimensions = {
      center: { lat: 59.744465, lng: 30.042834 },
      zoom: 8,
    };

    const {
      handleChange,
      handleDropdown,
      countryArray,
      cityArray,
      invalidcity,
      invalidemail,
      invalidcountry,
      invalidphone,
      invalidaddress,
      invalidwhats_app,
      invalidprofile_link,
      invalidskype,
      email,
      country,
      whats_app,
      profile_link,
      phone,
      skype,
      address,
      cityRef,
      countryRef,
      city,
      near_by_area,
      work_permit,
      latitude,
      longitude,
      files,
      shareForm
    } = this.props;

    return (
      <div className="tabInnerContent">
        <Row>
          <Col lg={4} sm={12}>
            <FormGroup>
              <Label for="emailAddress">
                Email Address<sup>*</sup>
              </Label>
              <Input
                type="email"
                className={invalidemail ? "bounce" : ""}
                name="email"
                value={email}
                onChange={handleChange}
                id="emailAddres"
                placeholder="Enter Email Address"
                disabled={shareForm ? true : false}
              />
            </FormGroup>
          </Col>
          <Col lg={4} sm={12}>
            <FormGroup>
              <Label for="skypeID">
                Skype ID
              </Label>
              <Input
                type="text"
                name="skype"
                value={skype}
                onChange={handleChange}
                // className={invalidskype ? "bounce" : ""}
                id="skypeID"
                placeholder="0123456789101"
              />
            </FormGroup>
          </Col>
          <Col lg={4} sm={12}>
            <FormGroup>
              <Label for="linkedinLink">
                Linkedin Profile Link
              </Label>
              <Input
                type="text"
                name="profile_link"
                value={profile_link}
                id="emergencyPhone"
                // className={invalidprofile_link ? "bounce" : ""}
                onChange={handleChange}
                placeholder=""
              />
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="mobilePhone">
                Mobile Phone Number<sup>*</sup>
              </Label>
              <Input
                type="tel"
                value={phone}
                className={invalidphone ? "bounce" : ""}
                onChange={handleChange}
                id="phone"
                name="phone"
              />
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="whatsppNumber">Whatsapp Number</Label>
              <Input
                type="tel"
                name="whats_app"
                value={whats_app}
                className={invalidwhats_app ? "bounce" : ""}
                onChange={handleChange}
                id="whatsapp"
              />
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="country">Country</Label>
              <Select
                options={countryArray}
                ref={countryRef}
                name="country"
                id="country"
                className={invalidcountry ? "bounce" : ""}
                value={countryArray?.filter((e: any) => e.value === country)}
                onChange={(e) => handleDropdown(e, "country")}
                placeholder="Select Country"
                isClearable
              />
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="city">City</Label>
              <Select
                options={cityArray}
                ref={cityRef}
                name="city"
                className={invalidcity ? "bounce" : ""}
                id="city"
                value={cityArray?.filter((e: any) => e.value === city)}
                placeholder="Select City"
                onChange={(e) => handleDropdown(e, "city")}
                isClearable
              />
            </FormGroup>
          </Col>
          <Col lg={12} sm={12}>
            <FormGroup>
              <Label for="address">
                Address<sup>*</sup>
              </Label>

              <AutoComplete
                defaultAddress={address}
                className={invalidaddress ? "bounce" : ""}
                selectLocation={this._onChange}
              />
            </FormGroup>
          </Col>
          <Col lg={12} sm={12}>
            <FormGroup>
              <div className="h-250">
                <AddressMap
                  center={{ lat: latitude, lng: longitude }}
                  addressUpdate={() => {}}
                  markers={[{ lat: latitude, lng: longitude }]}
                />                
              </div>
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="nearBy">
                Please select if you wish to cover any other nearby cities.
              </Label>
              <Select
                isMulti
                options={cityArray}
                value={near_by_area?.map((e) => {
                  return cityArray.find((c: any) => c.value === e);
                })}
                onChange={(e) => {
                  handleDropdown(e, "nearby");
                }}
                placeholder="Select Cities"
              />
            </FormGroup>
          </Col>
          <Col lg={6} sm={12}>
            <FormGroup>
              <Label for="workPermit">
                Do you require a work permit to work in this country.
              </Label>
              <Select
                options={permit}
                value={permit.find((x) => x.value === work_permit)}
                name="permit"
                id="permit"
                placeholder="Select permit"
                onChange={(e) => {
                  handleDropdown(e, "work_permit");
                }}
                isClearable
              />
            </FormGroup>
          </Col>
          {work_permit !== "yes" ? (
            <></>
          ) : (
            <Col lg={12} sm={12}>
              <FormGroup>
                <div className="docWrapper">
                  <Card className="cardBox">
                    <CardHeader className="d-flex justify-content-between align-items-center h-38 mb-0 br-0">
                      <div className="d-flex align-items-center">
                        <h2>Doucuments</h2>
                      </div>
                      <div className="d-flex align-items-center">
                        <Button
                          onClick={() => {
                            this.setState({
                              documentFormPopup: true,
                            });
                          }}
                          className="btn-size tableBtn"
                          outline
                        >
                          Add Document
                        </Button>
                      </div>
                    </CardHeader>
                    <CardBody>
                      <div className="colList">
                        <ul className="p-0 min-h-150">
                          {files.length > 0 ? (
                            files.map((e: any, index: any) => {
                              return (
                                <li>
                                  <div className="docContent d-flex justify-content-between align-items-center w-100">
                                    <div className="docName">
                                      <span>{e.name}</span>
                                    </div>
                                    <div className="docAction d-flex justify-content-end align-items-center">
                                      <Button
                                        outline
                                        onClick={() => {
                                          this.setState({
                                            confirmationPopup: true,
                                            name: e.name,
                                            documentIndex: index,
                                          });
                                        }}
                                        className="ml-2"
                                      >
                                        <FaTrash />
                                      </Button>
                                    </div>
                                  </div>
                                </li>
                              );
                            })
                          ) : (
                            <div className="noData">
                              <MdWarning size={32} />
                              <p>No File Available</p>
                            </div>
                          )}
                        </ul>
                      </div>
                    </CardBody>
                  </Card>
                </div>
              </FormGroup>
            </Col>
          )}
        </Row>
        <DocumentFormPopup
          modal={this.state.documentFormPopup}
          handleInputFile={this.handleFile}
          toggle={() => this.setState({ documentFormPopup: false })}
          type={"Add Document"}
        />
        <ConfirmationPopup
          modal={this.state.confirmationPopup}
          toggle={() => this.setState({ confirmationPopup: false })}
          type="Documents"
          handleDelete={this.handleDeleteFile}
          icon={<FaTrash />}
          message={"Are you sure you want to remove this Document?"}
        />
      </div>
    );
  }
}
