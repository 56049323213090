import { any } from "glamor";
import { IOutsideTech, ITechnician } from "../models/ITechnician";
import { updateTicketTechnicianListing } from "../redux/actions/ticketAction";
import httpServices from "./Helper/HttpService";

const createOutsideTechnician = async (outSideTech: any) => {
  try {
    return await httpServices.post<any>("/technicians", outSideTech);
  } catch (error) {
    throw error;
  }
};

const getTechnicians = async (
  country_id?: any, city_id?: any, latitude?: any, longitude?: any, skills?: any, skill_set?: any[],
  ne_lat?: any,
  sw_lat?: any,
  ne_lng?: any,
  sw_lng?: any): Promise<ITechnician[]> => {
  try {
    return await httpServices.post<ITechnician>("/get-technicians", {
      country_id: country_id,
      city_id: city_id,
      latitude: latitude,
      longitude: longitude,
      skills: skills,
      skills_set: skill_set,
      ne_lat:ne_lat,
      sw_lat:sw_lat,
      ne_lng:ne_lng,
      sw_lng:sw_lng,
    }).then((r) => { return r["data"] });
  } catch (error) {
    throw error;
  }
};

// const getTechnicians =  (country_id?: any, city_id?: any, latitude?: any, longitude?: any, skills?: any, skill_set?: any[]) => {
//   try {
//     return (dispatch: any) => {
//       httpServices.post<ITechnician>("/get-technicians", {
//         country_id: country_id,
//         city_id: city_id,
//         latitude: latitude,
//         longitude: longitude,
//         skills: skills,
//         skills_set: skill_set,
//       }).then((r: any) => {        
//         dispatch(updateTicketTechnicianListing(r?.data))
//         return r["data"]
//       });
//     }
//   } catch (error) {
//     throw error;
//   }
// };

const getToolKit = async () => {
  try {
    let response: any = await httpServices.get("/sf_toolkit");
    if (response && typeof response.data === 'string') {
      return []
    } else {
      return response
    }
  } catch (error) {
    throw error
  }
};

const getAllTechnicians = async (
  fullname?: any,
  address?: any,
  radius?: any,
  skillset?: any,
  language?: any,
  ne_lat?: any,
  ne_lng?: any,
  sw_lat?: any,
  sw_lng?: any,
) => {
  try {
    return await httpServices.post("/search-technicians", {
      full_name: fullname,
      address: address,
      radius: radius,
      skill_set: skillset,
      language: language,
      ne_lat: ne_lat,
      sw_lat: sw_lat,
      ne_lng: ne_lng,
      sw_lng: sw_lng,
    });
  } catch (error) {
    throw error
  }
};

const createTechnician = async (data: any) => {
  try {
    return await httpServices.postFormData<ITechnician>("/technicians",
      data
    ).then((r) => { return r["data"] });
  } catch (error) {
    throw error
  }
}

const createShareFormTechnician = async (data: any) => {
  try {
    return await httpServices.postFormData<ITechnician>("/shareform/technicians/store",
      data
    ).then((r) => { return r["data"] });
  } catch (error) {
    throw error
  }
}

const updateTechnician = async (id: any, data: any) => {
  try {
    let response = await httpServices.postFormData<ITechnician>(`/technicians/${id}`, data);
    return response['data']

  } catch (e) {    
    throw e;
  }
}

const deleteTechnicians = async (id: any) => {
  try {
    return await httpServices.remove(`/technicians/${id}`);
  } catch (error) {
    throw error
  }
};

const changeStatus = async (data: any) => {
  try {
    return await httpServices.post<any>("/technicians-changeStatus",
      {id:data.id,status:data.status}
    ).then((r) => { return r})
  } catch (error) {
    throw error
  }
}

const getTechnicianbyId = async (id: any) => {
  try {
    return await httpServices.get(`/technicians/${id}`);
  } catch (error) {
    throw error;
  }
};

const restoreTechnician = async (id: any) => {
  try {
    let data = {
      "id":id
    }
    return await httpServices.post(`/restore-technicians`,data);
  } catch (error) {
    throw error
  }
};

const deleteTechnicianDocument = async (id: any) => {
  try {
    return await httpServices.get(`/technicians-delete-docs/${id}`);
  } catch (error) {
    throw error
  }
};

const shateTechnicianForm = async (email: any,baseUrl:any) => {
  try {
    return await httpServices.post(`/shareform`, { "email": email,"share_form_base_url":baseUrl});
  } catch (error) {
    throw error
  }
};

const verifyShareFormToken = async (token: any) => {
  try {
    return await httpServices.get(`/shareform?token=${token}`);
  } catch (error) {
    throw error
  }
};

export default {
  createTechnician,
  createOutsideTechnician,
  getTechnicians,
  getAllTechnicians,
  deleteTechnicians,
  changeStatus,
  getToolKit,
  getTechnicianbyId,
  updateTechnician,
  restoreTechnician,
  deleteTechnicianDocument,
  shateTechnicianForm,
  verifyShareFormToken,
  createShareFormTechnician
};
